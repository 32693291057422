import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CONFIG } from "../../Components/Commons/constants/constant";

export const CurrencyPreferencesApi = createApi({
  reducerPath: "CurrencyPreferencesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: CONFIG.API_URL,
    prepareHeaders: (headers) => {
      headers.set("Access-Control-Allow-Origin", "*");
      headers.set("authorization", localStorage.getItem("jwt_token") || "");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    updateCoinStatus: builder.mutation({
      query: (data) => ({
        url: "currencyPreferences/updateCoinStatus",
        method: "POST",
        body: data,
      }),
    }),

    walletList: builder.query({
      query: () => ({
        url: "currencyPreferences/depositWalletsList",
        method: "GET",
      }),
    }),
  }),
});

export const { useWalletListQuery, useUpdateCoinStatusMutation } = CurrencyPreferencesApi;
export default CurrencyPreferencesApi;
