// src/validation/validationSchema.js
import * as yup from "yup";

const emailPattern = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;

export const loginSchema = yup
  .object({
    email: yup
      .string()
      .email("Invalid email address")
      .matches(emailPattern, "Invalid email format")
      .required("Email is required"),
    password: yup
      .string()
      // .min(8, "Password must be at least 8 characters")
      // .max(15, "Password must be at most 15 characters long")
      // .matches(
      //   /^(?=.*[a-z])/,
      //   "Password must contain at least one lowercase letter"
      // )
      // .matches(
      //   /^(?=.*[A-Z])/,
      //   "Password must contain at least one uppercase letter"
      // )
      // .matches(/^(?=.*\d)/, "Password must contain at least one digit")
      // .matches(
      //   /^(?=.*[!@#$%^&*()_+\[\]{}|;:,.<>?\\\/])/,
      //   "Password must contain at least one special character"
      // )
      .required("Password is required"),
  })
  .required();
