import React, { useState, useEffect } from "react";
import { CopyIcon, DownloadIcon, HashIcon } from "../../../Assets/Assets";
import { Table, DatePicker } from "antd";
import CustomDropdown from "../../Ui/CustomDropdown/CustomDropdown";
import RadioCustom from "../../Ui/radio/RadioCustom";
import "./TransactionDetails.scss";
import { ButtonCustom } from "../../Ui/button/ButtonCustom";
import {
  useFetchDataMutation,
  usePaymentassetsQuery,
} from "../../../Redux/apiService/userTransactionApi";
import { CONFIG } from "../../Commons/constants/constant";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Dropdown, Button, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { Path } from "../../../Routing/Constant/RoutePaths";
import { encryption } from "../../Commons/commonFunctions/encryption";
import useApiErrorHandler from "../../Commons/customHooks/useApiErrorHandler";
import {
  coinFamilySymbols,
  formatWalletAddress,
  handleCopy,
  formatNumber,
  exponentialToDecimal,
} from "../../Commons/commonFunctions/commonFunctions";
import moment from "moment";
import noImage from "./../../../Assets/noImage.png";
import { CalendarFilled } from "@ant-design/icons";
import dayjs from "dayjs";

function Refunds() {
  const { RangePicker } = DatePicker;
  const [TransactionApi] = useFetchDataMutation();
  const [selectedAsset, setSelectedAsset] = useState(null);
  const { data: assetsData, refetch } = usePaymentassetsQuery(undefined, {
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  });

  // State variables
  const [dataSource, setDataSource] = useState([]);
  const { handleApiError } = useApiErrorHandler();
  const [items, setItems] = useState([]);
  const [timeline, setTimeline] = useState(null);
  const [totalFiatAmount, setTotalFiatAmount] = useState(0);
  const [selectedRadio, setSelectedRadio] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [coinFamily, setCoinFamily] = useState(null);
  const [search, setSearch] = useState("");
  const merchantDetails = useSelector((state) => state?.user?.user);
  const [tableData, settableData] = useState([]);
  const timeZone = useSelector((state) => state?.user?.time_zone);

  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  // Handle date changes
  const onChange = (dates, dateStrings) => {
    setStartDate(dateStrings[0] || null);
    setEndDate(dateStrings[1] || null);
  };
  const location = useLocation();
  const currentLocation = location.pathname;
  const searchBarValue = useSelector((state) => state.search.searchValue);

  // Fetch refund data from API
  const fetchData = async (resetPage = false) => {
    try {
      setLoading(true);
      let pageToFetch = resetPage ? 1 : pagination.current;
      const payload = {
        type: "refund",
        page: pageToFetch,
        limit: pagination?.pageSize,
        coinFamily: coinFamily || undefined,
        search: search || undefined,
      };
      if (currentLocation === Path.TRANSACTIONS && searchBarValue) {
        payload.search = searchBarValue;
      }
      if (timeline) {
        payload.timeline = timeline;
      }
      if (startDate && endDate) {
        payload.startDate = startDate;
        payload.endDate = endDate;
      }
      if (selectedAsset) {
        payload.coinId = selectedAsset?.id;
      }
      await new Promise((resolve) => setTimeout(resolve, 500));
      const enc = await encryption(JSON.stringify(payload));
      const securedData = { dataString: enc };
      const response = await TransactionApi(securedData).unwrap();
      setTotalFiatAmount(response.totalFiatAmount || 0);

      const formattedData = response?.data?.map((item, index) => ({
        key: item?.id?.toString(),
        sno: (pagination?.current - 1) * pagination?.pageSize + index + 1,
        name: item?.merchant_data?.name || "N/A",
        pay_order_id: item?.pay_order_id || "N/A",
        user_email: item?.merchant_data?.email || "N/A",
        product: item?.coin_data?.coin_name || "N/A",
        wallet: (
          <>
            <span style={{ marginRight: "6px" }}>
              {formatWalletAddress(item?.from_address) || "N/A"}
            </span>
            <span onClick={() => handleCopy(item?.from_address)}>
              <CopyIcon />
            </span>
          </>
        ),
        date: formatDateTriskel(item?.created_at),
        amount:
          item?.amount !== undefined &&
          item?.coin_data?.decimal_value !== undefined
            ? exponentialToDecimal(
                formatNumber(item.amount, item.coin_data.decimal_value)
              )
            : "0.00",
        currency: `${item?.coin_data?.coin_symbol?.toUpperCase()} ${
          item?.coin_data?.is_token === 1
            ? `(${coinFamilySymbols[item?.coin_data?.coin_family]})`
            : ""
        }`,

        Status:
          item?.blockchain_status?.charAt(0)?.toUpperCase() +
          item?.blockchain_status?.slice(1),
        txn_hash: item.txn_hash,
        coinfamily: item.coin_data?.coin_family,
        refund_date: item?.refund_date
          ? formatDateTriskel(item?.refund_date)
          : "N/A",
        refund_txn_hash: item?.refund_txn_hash,
        refund_status:
          item?.refund_status === 0 || item?.refund_status === 3
            ? "Pending"
            : item?.refund_status === 1
            ? "In progress"
            : item?.refund_status === 2
            ? "Completed"
            : "N/A",
      }));

      setDataSource(formattedData);
      setPagination({
        ...pagination,
        current: response?.meta?.page,
        total: response?.meta?.total || 0,
      });
      setLoading(false);
    } catch (error) {
      console.log("ERRRRRR", error);
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(false);
  }, [
    timeline,
    startDate,
    endDate,
    // searchBarValue,
    selectedAsset,
    coinFamily,
    pagination?.current,
  ]);

  useEffect(() => {
    setPagination((prev) => ({ ...prev, current: 1 }));
    fetchData(true);
  }, [searchBarValue]);

  const formatDateTriskel = (inputDate) => {
    const date = new Date(inputDate);

    const options = {
      month: "short", // "Jan"
      day: "2-digit", // "03"
      year: "numeric", // "2024"
      hour: "numeric", // "4 PM"
      minute: "2-digit", // "30"
      hour12: true,
    };

    const formattedDateTime = date?.toLocaleDateString("en-US", options);
    const [formattedDate, yearPart, timePart] = formattedDateTime?.split(", ");

    return `${formattedDate}, ${yearPart} | ${timePart?.toUpperCase()}`;
  };

  const handleAssetSelect = (asset) => {
    setSelectedAsset({
      id: asset.id,
      image:
        asset?.coin_image != null
          ? `${CONFIG.S3_URL}/${asset?.coin_image}`
          : `${noImage}`,
      isToken: asset?.is_token,
      name: asset.coin_name,
    });

    setPagination((prev) => ({ ...prev, current: 1 }));
  };

  useEffect(() => {
    if (assetsData && assetsData.status === true) {
      const formattedItems = assetsData?.data?.map((asset) => ({
        label: (
          <p onClick={() => handleAssetSelect(asset)}>
            <img
              src={
                asset?.coin_image != null
                  ? `${CONFIG.S3_URL}/${asset.coin_image}`
                  : `${noImage}`
              }
              alt={`${asset?.coin_name} icon`}
              height={20}
              width={20}
            />{" "}
            {asset?.coin_name}{" "}
            <small>
              {asset?.is_token === 1 && asset?.coin_family ? (
                <span className="coin-symbol">
                  ({coinFamilySymbols[asset?.coin_family]})
                </span>
              ) : null}
            </small>
          </p>
        ),
        key: asset.id,
      }));
      setItems(formattedItems);
    }
  }, [assetsData]);

  const menuProps = {
    items,
  };
  const handleTableChange = (pagination) => {
    setPagination({
      ...pagination,
      current: pagination?.current,
    });
  };

  const downloadList = () => {
    // Construct the URL with query parameters based on the current state
    const baseUrl = `${CONFIG.API_URL}txnHistory/download`;
    const params = new URLSearchParams();

    // Mandatory parameters
    params.append("type", "refund");
    params.append("merchantId", merchantDetails?.id);

    if (selectedAsset) {
      params.append("coinId", selectedAsset?.id);
    }
    if (timeline) {
      params.append("timeline", timeline);
    }
    if (startDate) {
      params.append("startDate", startDate);
    }
    if (endDate) {
      params.append("endDate", endDate);
    }
    params.append("page", pagination?.current);
    params.append("limit", pagination?.pageSize);
    if (currentLocation === Path.TRANSACTIONS && searchBarValue !== "") {
      params.append("search", searchBarValue);
    }
    if (timeZone) {
      params.append("timeZone", timeZone);
    }
    // Generate the final link
    const link = `${baseUrl}?${params?.toString()}`;
    console.log("LINKKKKKK", link);
    window.open(link);
  };

  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      key: "sno",
      render: (_, __, index) =>
        (pagination.current - 1) * pagination.pageSize + index + 1,
    },

    {
      title: "Merchant Email ID",
      dataIndex: "user_email",
      key: "user_email",
    },

    {
      title: "User Wallet Address",
      dataIndex: "wallet",
      key: "wallet",
    },
    {
      title: " Transaction Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Transaction Hash",
      dataIndex: "item",
      key: "item",
      render: (_, item) => (
        <>
          {item?.txn_hash
            ? `${item.txn_hash.slice(0, 6)}...${item.txn_hash.slice(-4)}`
            : "N/A"}{" "}
          {item?.txn_hash != null ? (
            <span onClick={() => onPressView(item)}>
              <HashIcon />
            </span>
          ) : null}
        </>
      ),
    },
    {
      title: "Date of Refund",
      dataIndex: "refund_date",
      key: "refund_date",
    },
    {
      title: "Refund Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
    },
    {
      title: "Refund Hash",
      dataIndex: "refund_txn_hash",
      key: "refund_txn_hash",
      render: (_, item) => (
        <>
          {item?.refund_txn_hash
            ? `${item.refund_txn_hash.slice(
                0,
                6
              )}...${item.refund_txn_hash.slice(-4)}`
            : "NA"}{" "}
          {item?.refund_txn_hash != null ? (
            <span onClick={() => onRefundPressView(item)}>
              <HashIcon />
            </span>
          ) : null}
        </>
      ),
    },
    // {
    //   title: "Transaction Status",
    //   dataIndex: "Status",
    //   key: "Status",
    // },

    {
      title: " Refund status",
      dataIndex: "refund_status",
      key: "refund_status",
      render: (refund_status) => {
        let statusColor = "";
        console.log("refund_status", refund_status);

        switch (refund_status?.toLowerCase()) {
          case "pending":
            statusColor = "#FFD700"; // Yellow
            break;
          case "failed":
            statusColor = "#FF4D4F"; // Red
            break;
          case "completed":
            statusColor = "#52C41A"; // Green
            break;
          default:
            statusColor = "gray"; // Default color for unknown statuses
        }

        return refund_status ? (
          <span style={{ color: statusColor }}>{refund_status}</span>
        ) : (
          "N/A"
        );
      },
    },
  ];
  const onPressView = (item) => {
    const { coinfamily } = item;

    if (coinfamily == 2) {
      return window.open("https://etherscan.io/tx/" + item?.txn_hash);
    } else if (coinfamily == 3) {
      return window.open("https://bscscan.com/tx/" + item?.txn_hash);
    } else if (coinfamily == 1) {
      return window.open(
        "https://live.blockcypher.com/btc/tx/" + item?.txn_hash
      );
    } else if (coinfamily == 4) {
      return window.open("https://polygonscan.com/tx/" + item?.txn_hash);
    } else if (coinfamily == 5) {
      return window.open(
        "https://tronscan.org/#/transaction/" + item?.txn_hash
      );
    }
  };
  const onRefundPressView = (item) => {
    const { coinfamily } = item;

    if (coinfamily == 2) {
      return window.open("https://etherscan.io/tx/" + item?.txn_hash);
    } else if (coinfamily == 3) {
      return window.open("https://bscscan.com/tx/" + item?.txn_hash);
    } else if (coinfamily == 1) {
      return window.open(
        "https://live.blockcypher.com/btc/tx/" + item?.txn_hash
      );
    } else if (coinfamily == 4) {
      return window.open("https://polygonscan.com/tx/" + item?.txn_hash);
    } else if (coinfamily == 5) {
      return window.open(
        "https://tronscan.org/#/transaction/" + item?.txn_hash
      );
    }
  };

  const resetAllFilters = () => {
    setEndDate(null);
    setStartDate(null);
    setTimeline(null);
    setSelectedAsset(null);
    setSelectedRadio("");
  };

  return (
    <div className="ongoingCompleted">
      <div className="ongoingCompleted_filter">
        <div className="ongoingCompleted_filter_btns">
          {!timeline ? (
            <RangePicker
              onChange={onChange}
              value={
                startDate && endDate ? [dayjs(startDate), dayjs(endDate)] : []
              }
              suffixIcon={<CalendarFilled style={{ color: "black" }} />}
            />
          ) : null}
          <Dropdown menu={menuProps}>
            <Button>
              {selectedAsset ? (
                <img src={selectedAsset.image} alt="" height={20} width={20} />
              ) : (
                ""
              )}
              {selectedAsset?.name || "Filter By Asset"}
              <DownOutlined />
            </Button>
          </Dropdown>
          {!startDate && !endDate ? (
            <>
              <RadioCustom
                radioText="Last Month"
                checked={selectedRadio === "1m"}
                onChange={() => {
                  setTimeline("1m");
                  setSelectedRadio("1m");
                }}
              />
              <RadioCustom
                radioText="Last 3 Months"
                checked={selectedRadio === "3m"}
                onChange={() => {
                  setTimeline("3m");
                  setSelectedRadio("3m");
                }}
              />
              <RadioCustom
                radioText="Last 6 Months"
                checked={selectedRadio === "6m"}
                onChange={() => {
                  setTimeline("6m");
                  setSelectedRadio("6m");
                }}
              />
              <RadioCustom
                radioText="Last 1 Year"
                checked={selectedRadio === "1y"}
                onChange={() => {
                  setTimeline("1y");
                  setSelectedRadio("1y");
                }}
              />
            </>
          ) : null}
          <Button onClick={resetAllFilters}>Reset All Filters</Button>
        </div>
        <div className="ongoingCompleted_filter_btns">
          <ButtonCustom
            leftIcon={<DownloadIcon />}
            label="Download CSV"
            className="downloadBtn"
            onClick={downloadList}
            disabled={dataSource?.length < 1}
          />
        </div>
      </div>
      <div className="UserTable">
        <Table
          dataSource={dataSource}
          columns={columns}
          tableLayout={"auto"}
          scroll={{ x: "max-content" }}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
          }}
          onChange={handleTableChange}
        />
      </div>
    </div>
  );
}

export default Refunds;
