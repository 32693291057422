// import NodeRSA from 'node-rsa';
import JSEncrypt from "jsencrypt";
const RSAkey = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAu4JjAixssKIgWPrBofaZ
AaIXUnDLToaQA2YndzeR/MmFdqncUsdtSvywVcEBPwMhsGXa24FgEQz1Q2g3yyk/
ZVzjofuXWBAFVe6AevXbwpbgz7OGAfn6vNNWr1EV0GDw/tFCNDHtNMLmCp0iF49U
Tv/eCTHcwRUvNU6O0Bzkm5w4SYuLtiA5IOD+rVE9aw1efwPiI2aQP0Yzlwuy/amg
Ild8b/xC7HxZNi3e2lJSmrzxWHmPnLw8DK+m9R25oDH5OianqdCU5A38SM0XaXpv
zZgWXBB7g7uq4mygvh0xjffRoBtyK1OKTytLwqtvwiQR61DT0BQ1xwcSMPph2Os6
ZQIDAQAB
-----END PUBLIC KEY-----`;

// Assuming you're using JSEncrypt for encryption

export const encryption = async (data) => {
  console.log("DATATATA Payload in encryption", data);
  try {
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(RSAkey);
    const encStr = encrypt.encrypt(data);
    return encStr;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
