import { Button } from "antd";
import React from "react";
import "./ButtonCustomStyle.scss";

const ButtonCustom = (props) => {
  const {
    lessPadding,
    leftIcon,
    label,
    onlyIcon,
    rightIcon,
    medium,
    large,
    xLarge,
    black,
    customClass,
    borderBtn,
    disabled,
    onClick,
    greenBtn,
  } = props;
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      className={`btnCustom  ${greenBtn ? "greenBtn" : ""}  ${
        borderBtn ? "borderBtn" : ""
      } ${lessPadding ? "lessPadding" : ""}${xLarge ? "xLarge" : ""} ${medium ? "medium" : ""}${
        black ? "black" : ""
      } ${large ? "large" : ""} ${customClass}`}
      {...props}
    >
      {leftIcon && <span className="leftIcon">{leftIcon}</span>}
      {label && <span className="fontFamilyText">{label}</span>}
      {onlyIcon && <span className="onlyIcon">{onlyIcon}</span>}
      {rightIcon && <span className="rightIcon">{rightIcon}</span>}
    </Button>
  );
};

export { ButtonCustom };
