import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../../Header/Header";
import SidebarDashboard from "../../SidebarDashboard/SidebarDashboard";
import "./DashboardLayout.scss";
import Footer from "../../Footer/Footer";

function DashboardLayout() {
  return (
    <>
      <div className="dashLayout">
        <SidebarDashboard />
        <div className="dashLayout_content">
          <div className="dashLayout_content_header">
            <Header />
          </div>
          <div className="dashLayout_content_body">
            <Outlet />
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default DashboardLayout;
