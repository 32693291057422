import { Tabs } from "antd";
import React from "react";
import CustomDropdown from "../../Ui/CustomDropdown/CustomDropdown";
import "./TransactionDetails.scss";
import OngoingCompleted from "./OngoingCompleted";
import Refunds from "./Refunds";
function TransactionDetails() {
  const onChange = (key) => {};
  const items = [
    {
      key: "1",
      label: "Ongoing/Completed",
      children: <OngoingCompleted />,
    },
    {
      key: "2",
      label: "Refunds",
      children: <Refunds />,
    },
  ];
  return (
    <div className="transactionDetails">
      <div>
        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
      </div>
    </div>
  );
}

export default TransactionDetails;
