import { Content } from "antd/es/layout/layout";
import React from "react";
import "./OnboardAuthLayout.scss";
import { Link, Outlet, useLocation } from "react-router-dom";
import banner from "../../../Assets/LoginBanner.png";
import { Path } from "../../../Routing/Constant/RoutePaths";
import logo from "../../../Assets/logo.svg";

function OnboardAuthLayout() {
  const location = useLocation();
  const currentLocation = location.pathname;
  return (
    <>
      <div className="loginHeader">
        <Link>
          <img className="logo" src={logo} alt="" />
        </Link>
      </div>
      <Content>
        <div className="flexAuto">
          <div className="loginScreen">
            <div className="loginScreen__leftCol">
              <img src={banner} alt="" />
              <h2>Welcome to Triskel Merchant Portal</h2>
            </div>
            {/* ----------------------------------------------------------- */}
            <div className="loginScreen__rytCol">
              <h2>
                {(currentLocation === Path.LOGIN ||
                  currentLocation === Path.HOME) &&
                  "Triskel Pay - Merchant Login"}
                {currentLocation === Path.FORGOTPASSWORD &&
                  "Reset Your Password"}
              </h2>
              <Outlet />
            </div>
          </div>
        </div>
      </Content>
      {/* <Footer /> */}
    </>
  );
}

export default OnboardAuthLayout;
