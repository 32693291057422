import React, { useEffect, useState } from "react";
import "./SidebarDashboard.scss";
import { Link, useLocation } from "react-router-dom";
import { Path } from "../../Routing/Constant/RoutePaths.jsx";
import useCurrentWidth from "../Commons/customHooks/useCurrentWidth.jsx";
import logo from "../../Assets/logo.svg";
import smallLogo from "../../Assets/smallLogo.png";
import {
  CurrencyIcon,
  DashboardIcon,
  MerchantIcon,
  PaymentDetails,
  SettingsIcon,
  TransactionsIcon,
} from "../../Assets/Assets.jsx";

function SidebarDashboard() {
  const [collapsed, setCollapsed] = useState(true);
  const width = useCurrentWidth();
  const location = useLocation();
  const currentPath = location.pathname;

  const menuItems = [
    {
      to: Path.DASHBOARD,
      icon: <DashboardIcon />,
      label: "Dashboard",
    },
    {
      to: Path.TRANSACTIONS,
      icon: <TransactionsIcon />,
      label: "User Transactions",
    },
    {
      to: Path.PREFERENCES,
      icon: <CurrencyIcon />,
      label: "Currency Preferences",
    },
    {
      to: Path.PAYMENTDETAILS,
      icon: <PaymentDetails />,
      label: "Payment Details",
    },
    {
      to: Path.MERCHANTCONFIG,
      icon: <MerchantIcon />,
      label: "Merchant Config",
    },
    {
      to: Path.SETTINGS,
      icon: <SettingsIcon />,
      label: "Settings",
    },
  ];

  useEffect(() => {
    if (width > 991) {
      setCollapsed(false);
    } else {
      setCollapsed(true);
    }
  }, [width]);

  return (
    <div className={`sidebar ${width <= 991 ? "sidebarResponsive" : ""}`}>
      <div className="sidebar_logo">
        <Link to={Path.DASHBOARD}>
          <img className="logo" src={logo} alt="" />
          <img className="smallLogo" src={smallLogo} alt="" />
        </Link>
      </div>
      <div className="sidebar_bottom">
        <div className="sidebar_link">
          <div className="sidebar_link_items">
            {menuItems?.map((ele, index) => (
              <Link
                key={index}
                to={ele.to}
                className={currentPath === ele.to ? "active" : ""}
              >
                <div className="sidebar_link_items_icon"> {ele.icon}</div>
                {!collapsed && ele.label}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SidebarDashboard;
