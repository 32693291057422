import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CONFIG } from "../../Components/Commons/constants/constant";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({ baseUrl: CONFIG.API_URL }), // Replace with your API's base URL

  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "auth/login", // Replace with your login endpoint
        method: "POST",
        body: credentials,
      }),
    }),
    forgotPassword: builder.mutation({
      query: (data) => ({
        url: "auth/forgotPassword", // Replace with your login endpoint
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const { useLoginMutation, useForgotPasswordMutation } = authApi;
