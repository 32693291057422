import React, { useState, useEffect } from "react";
import {
  CalendarIcon,
  CopyIcon,
  DownloadIcon,
  HashIcon,
} from "../../../Assets/Assets";
import { Table, DatePicker } from "antd";
import RadioCustom from "../../Ui/radio/RadioCustom";
import "./TransactionDetails.scss";
import { ButtonCustom } from "../../Ui/button/ButtonCustom";
import {
  useFetchDataMutation,
  usePaymentassetsQuery,
} from "../../../Redux/apiService/userTransactionApi";
import { useSelector } from "react-redux";
import { encryption } from "../../Commons/commonFunctions/encryption";
import { useLocation } from "react-router-dom";
import { CONFIG } from "../../Commons/constants/constant";
import { Dropdown, Button, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { Path } from "../../../Routing/Constant/RoutePaths";
import useApiErrorHandler from "../../Commons/customHooks/useApiErrorHandler";
import noImage from "./../../../Assets/noImage.png";
import {
  coinFamilySymbols,
  firstUpperCase,
  formatWalletAddress,
  handleCopy,
  exponentialToDecimal,
  formatNumber
} from "../../Commons/commonFunctions/commonFunctions";
import moment from "moment";
import { CalendarFilled } from "@ant-design/icons";

import dayjs from "dayjs";
function OngoingCompleted() {
  const { RangePicker } = DatePicker;
  const [TransactionApi] = useFetchDataMutation();
  const [selectedAsset, setSelectedAsset] = useState(null);
  const { data: assetsData, refetch } = usePaymentassetsQuery(undefined, {
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  });

  // State variables
  const [dataSource, setDataSource] = useState([]);
  const { handleApiError } = useApiErrorHandler();
  const [timeline, setTimeline] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const merchantDetails = useSelector((state) => state?.user?.user);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [totalPayments, setTotalPayments] = useState(0);
  const [selectedRadio, setSelectedRadio] = useState("");
  const timeZone = useSelector((state) => state?.user?.time_zone);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  // Handle date changes
  const onChange = (dates, dateStrings) => {
    setStartDate(dateStrings[0] || null);
    setEndDate(dateStrings[1] || null);
  };

  const location = useLocation();
  const currentLocation = location.pathname;
  const searchBarValue = useSelector((state) => state.search.searchValue);

  // Fetch data from API
  const fetchData = async (resetPage = false) => {
    try {
      setLoading(true);
      let pageToFetch = resetPage ? 1 : pagination.current;
      const payload = {
        type: "all",
        page: pageToFetch,
        limit: pagination?.pageSize,
        // timeline: timeline || undefined,
      };
      if (currentLocation === Path.TRANSACTIONS && searchBarValue) {
        payload.search = searchBarValue;
      }
      if (timeline) {
        payload.timeline = timeline;
      }
      if (startDate && endDate) {
        payload.startDate = startDate;
        payload.endDate = endDate;
      }
      if (selectedAsset) {
        payload.coinId = selectedAsset?.id;
      }

      console.log("PAYLOAD", payload);
      await new Promise((resolve) => setTimeout(resolve, 500));
      // Make the API request with the cleaned payload
      const enc = await encryption(JSON.stringify(payload));
      const securedData = { dataString: enc };
      const response = await TransactionApi(securedData).unwrap();

      setDataSource(response?.data);
      setPagination({
        ...pagination,
        current: response?.meta?.page,
        total: response?.meta?.total || 0,
      });
      setTotalPayments(response?.totalFiatAmount || 0);
      setLoading(false);
    } catch (error) {
      console.log("ERRRRRR", error);
      handleApiError(error);
    }
  };

  useEffect(() => {
    fetchData(false);
  }, [
    timeline,
    startDate,
    // searchBarValue,
    selectedAsset,
    endDate,
    pagination?.current,
  ]);

  useEffect(() => {
    setPagination((prev) => ({ ...prev, current: 1 }));
    fetchData(true);
  }, [searchBarValue]);

  const handleAssetSelect = (asset) => {
    setSelectedAsset({
      id: asset.id,
      image:
        asset?.coin_image != null
          ? `${CONFIG.S3_URL}/${asset?.coin_image}`
          : `${noImage}`,
      isToken: asset?.is_token,
      name: asset.coin_name,
    });

    setPagination((prev) => ({ ...prev, current: 1 }));
  };

  useEffect(() => {
    if (assetsData && assetsData?.status === true) {
      const formattedItems = assetsData?.data.map((asset) => ({
        label: (
          <p onClick={() => handleAssetSelect(asset)}>
            <img
              src={
                asset.coin_image != null
                  ? `${CONFIG.S3_URL}/${asset.coin_image}`
                  : `${noImage}`
              }
              alt={`${asset.coin_name} icon`}
              height={20}
              width={20}
            />{" "}
            {asset?.coin_name}{" "}
            <small>
              {asset?.is_token === 1 && asset?.coin_family ? (
                <span className="coin-symbol">
                  ({coinFamilySymbols[asset?.coin_family]})
                </span>
              ) : null}
            </small>
          </p>
        ),
        key: asset.id,
      }));
      setItems(formattedItems);
    }
  }, [assetsData]);

  const menuProps = {
    items,
  };

  const handleTableChange = (pagination) => {
    setPagination({
      ...pagination,
      current: pagination?.current,
    });
  };

  const downoaldList = () => {
    // alert("IN LIST");

    // Construct the URL with query parameters based on the current state
    const baseUrl = `${CONFIG.API_URL}txnHistory/download`;
    let queryParams = {
      type: "all",
      merchantId: merchantDetails?.id,
      page: pagination?.current,
      limit: pagination?.pageSize,
    };
    if (timeZone) {
      queryParams.timeZone = timeZone;
    }
    if (currentLocation === Path.TRANSACTIONS && searchBarValue !== "") {
      queryParams.search = searchBarValue;
    }
    if (startDate && endDate) {
      queryParams.startDate = startDate;
      queryParams.endDate = endDate;
    }
    if (selectedAsset) {
      queryParams.coinId = selectedAsset?.id;
    }
    if (timeline) {
      queryParams.timeline = timeline;
    }

    const params = new URLSearchParams(queryParams)?.toString();

    const link = `${baseUrl}?${params}`;
    console.log("LINKKKKK", link);
    window.open(link);
  };
  function formatDateTime(dateString) {
    const date = new Date(dateString);
    const options = {
      month: "short",
      day: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };
    const formattedDateTime = date?.toLocaleDateString("en-US", options);
    const [formattedDate, yearPart, timePart] = formattedDateTime?.split(", ");
    return `${formattedDate}, ${yearPart} | ${timePart?.toUpperCase()}`;
  }

  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      key: "sno",
      render: (_, __, index) =>
        (pagination.current - 1) * pagination.pageSize + index + 1,
    },

    {
      title: "Order ID",
      dataIndex: "pay_order_id",
      key: "pay_order_id",
      render: (pay_order_id) => <div>{pay_order_id || "N/A"}</div>,
    },
    {
      title: "User Email ID",
      dataIndex: "user_email",
      key: "user_email",
      render: (user_email) => <div>{user_email || "N/A"}</div>,
    },

    {
      title: "User Wallet Address",
      dataIndex: "from_address",
      key: "from_address",
      render: (from_address) => (
        <>
          {formatWalletAddress(from_address)}
          <span
            style={{ marginLeft: "8px", cursor: "pointer" }}
            onClick={() => handleCopy(from_address)}
          >
            <CopyIcon />
          </span>
        </>
      ),
    },
    {
      title: "Date of Transaction",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => formatDateTime(created_at),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (_, item) => (
        <p>
          {item?.amount !== undefined && item?.coin_data?.decimal_value !== undefined ? (
            exponentialToDecimal(
              formatNumber(item.amount, 
              item.coin_data.decimal_value )
            )
          ) : (
            "0.00"
          )}
        </p>
      ),
    },
    // {
    //   title: "Amount (USD)",
    //   dataIndex: "amount_in_usd",
    //   key: "amount",
    // },

    // Add the currency column here
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
      render: (_, item) => (
        <p>
          {item?.coin_data?.coin_symbol?.toUpperCase()}{" "}
          {item?.coin_data?.is_token === 1 && item?.coin_data?.coin_family ? (
            <span className="coin-symbol">
              ({coinFamilySymbols[item?.coin_data?.coin_family]})
            </span>
          ) : null}
        </p>
      ),
    },

    {
      title: "Transaction Hash",
      dataIndex: "txn_hash",
      key: "txn_hash",
      render: (_, item) => (
        <>
          {console.log("txn_hash", item)}
          {item?.txn_hash
            ? `${item.txn_hash.slice(0, 6)}...${item.txn_hash.slice(-4)}`
            : "N/A"}
          {item?.txn_hash != null ? (
            <span onClick={() => onPressView(item)}>
              {" "}
              <HashIcon />
            </span>
          ) : null}
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "blockchain_status",
      key: "blockchain_status",
      render: (blockchain_status) => {
        let statusColor = "";

        switch (blockchain_status?.toLowerCase()) {
          case "pending":
            statusColor = "#FFD700"; // Yellow
            break;
          case "failed":
            statusColor = "#FF4D4F"; // Red
            break;
          case "confirmed":
            statusColor = "#52C41A"; // Green
            break;
          default:
            statusColor = "gray"; // Default color for unknown statuses
        }

        return blockchain_status ? (
          <span style={{ color: statusColor }}>
            {firstUpperCase(blockchain_status)}
          </span>
        ) : (
          "N/A"
        );
      },
    },
  ];

  const onPressView = (item) => {
    const { txn_hash } = item;
    const { coin_data } = item;
    let coin_family = coin_data?.coin_family;

    if (coin_family == 2) {
      return window.open("https://etherscan.io/tx/" + txn_hash);
    } else if (coin_family == 3) {
      return window.open("https://bscscan.com/tx/" + txn_hash);
    } else if (coin_family == 1) {
      return window.open("https://live.blockcypher.com/btc/tx/" + txn_hash);
    } else if (coin_family == 4) {
      return window.open("https://polygonscan.com/tx/" + txn_hash);
    } else if (coin_family == 5) {
      return window.open("https://tronscan.org/#/transaction/" + txn_hash);
    }
  };

  const resetAllFilters = () => {
    setEndDate(null);
    setStartDate(null);
    setTimeline(null);
    setSelectedAsset(null);
    setSelectedRadio("");
  };
  return (
    <div className="ongoingCompleted">
      <div className="ongoingCompleted_filter">
        <div className="ongoingCompleted_filter_btns">
          {!timeline ? (
            <RangePicker
              onChange={onChange}
              value={
                startDate && endDate ? [dayjs(startDate), dayjs(endDate)] : []
              }
              suffixIcon={<CalendarFilled style={{ color: "black" }} />}
            />
          ) : null}
          <Dropdown menu={menuProps}>
            <Button>
              {selectedAsset ? (
                <img
                  src={selectedAsset.image}
                  alt={"noImage"}
                  height={20}
                  width={20}
                />
              ) : (
                ""
              )}
              {selectedAsset?.name || "Filter By Asset"}
              <DownOutlined />
            </Button>
          </Dropdown>
          {!startDate && !endDate ? (
            <>
              <RadioCustom
                radioText="Last Month"
                checked={selectedRadio === "1m"}
                onChange={() => {
                  setTimeline("1m");
                  setSelectedRadio("1m");
                }}
              />
              <RadioCustom
                radioText="Last 3 Months"
                checked={selectedRadio === "3m"}
                onChange={() => {
                  setTimeline("3m");
                  setSelectedRadio("3m");
                }}
              />
              <RadioCustom
                radioText="Last 6 Months"
                checked={selectedRadio === "6m"}
                onChange={() => {
                  setTimeline("6m");
                  setSelectedRadio("6m");
                }}
              />
              <RadioCustom
                radioText="Last 1 Year"
                checked={selectedRadio === "1y"}
                onChange={() => {
                  setTimeline("1y");
                  setSelectedRadio("1y");
                }}
              />
            </>
          ) : null}
          <Button onClick={resetAllFilters}>Reset All Filters</Button>
        </div>
        <div className="ongoingCompleted_filter_btns">
          {/* <div className="greenDivBg">
            <p>
              Total Amount: <span>${totalPayments.toFixed(2)}</span>
            </p>
          </div> */}
          <ButtonCustom
            leftIcon={<DownloadIcon />}
            label="Download CSV"
            className="downloadBtn"
            onClick={downoaldList}
            disabled={dataSource?.length < 1}
          />
        </div>
      </div>
      <div className="UserTable">
        <Table
          dataSource={dataSource}
          columns={columns}
          tableLayout={"auto"}
          scroll={{ x: "max-content" }}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
            showSizeChanger: false,
          }}
          onChange={handleTableChange}
          loading={loading}
        />
      </div>
    </div>
  );
}

export default OngoingCompleted;
