import React from "react";
import DashboardLayout from "../../Components/Layouts/DashboardLayout/DashboardLayout.jsx";
import { Navigate } from "react-router-dom";
import { Path } from "../Constant/RoutePaths.jsx";
const Private = () => {
  const isAuthenticated = localStorage.getItem("jwt_token");
  return isAuthenticated ? (
    <DashboardLayout />
  ) : (
    <Navigate to={Path.LOGIN} replace={true} />
  );
};

export default Private;