import React, { useEffect, useState } from "react";
import "./Header.scss";
import { LogoutIcon, SearchIcon } from "../../Assets/Assets";
import { useLocation, useNavigate } from "react-router-dom";
import { Path } from "../../Routing/Constant/RoutePaths.jsx";
import InputCustom from "../Ui/input/InputCustom.jsx";
import { useDispatch, useSelector } from "react-redux";
import { setSearchValue } from "../../Redux/slices/searchSlice";
import { logout, setTimeZone } from "../../Redux/slices/userSlice";
import { Flex, Modal } from "antd";
import { ButtonCustom } from "../Ui/button/ButtonCustom";
import { CONFIG } from "../Commons/constants/constant.js";
function Header() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentLocation = location.pathname;
  const [searchInputValue, setSearchInputValue] = useState("");
  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchInputValue(value);
    dispatch(setSearchValue(value.trim())); // Use the value directly from the event
  };
  const [isModalVisible, setIsModalVisible] = useState(false);
  const merchantDetails = useSelector((state) => state?.user?.user);

  useEffect(() => {
    const detectedTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    console.log("detectedTimezone >>>", detectedTimezone);
    dispatch(setTimeZone(detectedTimezone));
  }, []);

  const handleLogOut = () => {
    dispatch(logout());
    navigate("/login");
  };
  useEffect(() => {
    dispatch(setSearchValue(""));
    setSearchInputValue("");
  }, [location]);

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="header">
      <h3>
        {currentLocation === Path.DASHBOARD && "Dashboard"}
        {currentLocation === Path.TRANSACTIONS && "User Transactions"}
        {currentLocation === Path.PREFERENCES &&
          "Currency Preferences (User Deposit)"}
        {currentLocation === Path.PAYMENTDETAILS && "Payment Details"}
        {currentLocation === Path.SETTINGS && "Settings"}
        {currentLocation === Path.NEWMERCHANT && "Merchant Configuration "}
        {currentLocation === Path.MERCHANTCONFIG && "Merchant Configuration"}
      </h3>
      <div className="header_right">
        <InputCustom
          basicInput
          inputSearch
          searchImg={<SearchIcon />}
          inputCustum
          placeholder="Search"
          value={searchInputValue}
          maxLength={100}
          onChange={handleSearch} // Pass handleSearch to InputCustom
          disabled={
            currentLocation === Path.SETTINGS ||
            currentLocation === Path.DASHBOARD ||
            currentLocation === Path.PREFERENCES ||
            currentLocation === Path.NEWMERCHANT ||
            currentLocation === Path.MERCHANTCONFIG
          }
        />
        <Flex gap={10} align="center">
          <img
            style={{ borderRadius: "50%" }}
            src={`${CONFIG.S3_URL}/${merchantDetails?.logo}`}
            alt=""
            height={30}
            width={30}
          />
          <p>{merchantDetails?.email}</p>
        </Flex>
        <div
          className="header_right_logOut"
          onClick={() => setIsModalVisible(true)}
          style={{ cursor: "pointer" }}
        >
          <LogoutIcon />
          <h5>Logout</h5>
        </div>
      </div>
      <Modal
        open={isModalVisible}
        onOk={handleModalClose}
        // centered
        onCancel={handleModalClose}
        footer={null}
        maskClosable={false}
        className="common-modal alertModal"
      >
        <div className="modal-content">
          <p>Are you sure you want to logout?</p>
          <div className="alertModal_footer">
            <ButtonCustom
              greenBtn
              large
              label="Yes"
              onClick={() => handleLogOut()}
            />
            <ButtonCustom
              borderBtn
              large
              label="No"
              onClick={handleModalClose}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Header;
