import React, { useEffect, useState } from "react";
import "./Dashboard.scss";
import {
  DollerSwapIcon,
  EarnedPayoutsIcon,
  CopyIcon,
} from "../../../Assets/Assets";
import { CONFIG } from "../../Commons/constants/constant";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Area,
  AreaChart,
  Label,
} from "recharts";
import {
  useDashboardKeyQuery,
  useDashboardDataMutation,
  useDashboardBarDataMutation,
} from "../../../Redux/apiService/dashboardApi";
import useApiErrorHandler from "../../Commons/customHooks/useApiErrorHandler";
import { Dropdown, Button, Space, Flex } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useAssestQuery } from "../../../Redux/apiService/paymentApi";
import {
  coinFamilySymbols,
  formatApiKey,
  handleCopy,
  formatNumber,
  exponentialToDecimal,
} from "../../Commons/commonFunctions/commonFunctions";
import { encryption } from "../../Commons/commonFunctions/encryption";
import { useDashboardPIDataMutation } from "../../../Redux/apiService/dashboardApi";
import Box from "@mui/material/Box";
import { PieChart } from "@mui/x-charts/PieChart";
import noImage from "./../../../Assets/noImage.png";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setPublicKeyCrypto } from "../../../Redux/slices/userSlice";
import NoData from "../../Commons/noData/NoData";

const Dashboard = () => {
  const [filter, setFilter] = useState("1D");
  const [earnPay, setEarnPay] = useState("1D");
  const dispatch = useDispatch();
  const [volPayments, setVolPayments] = useState("1D");
  const { handleApiError } = useApiErrorHandler();
  const [DashboardData] = useDashboardDataMutation();
  const [dashboardPIChartData] = useDashboardPIDataMutation();
  const [dashboardBarData] = useDashboardBarDataMutation();
  const { data: assetsData, refetch: refetchAssets } = useAssestQuery();
  const [items, setItems] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState({
    id: 1,
    image: `${CONFIG.S3_URL}/triskel-pay/btc.png`,
  });

  const [earnedPayoutsAsset, setEarnedPayoutsAsset] = useState({
    id: 1,
    image: `${CONFIG.S3_URL}/triskel-pay/btc.png`,
  });

  const [userAmountAsset, setUserAmountAsset] = useState({
    id: 1,
    image: `${CONFIG.S3_URL}/triskel-pay/btc.png`,
  });

  const [earnedPayouts, setEarnedPayouts] = useState(null);
  const [userPaymentVolume, setUserPaymentVolume] = useState(null);

  const [pichartData, setPichartData] = useState([]);
  const dummypichartData = [{ label: "No data", value: 1, color: " #808080" }];
  const timeZone = useSelector((state) => state?.user?.time_zone);

  const [barChartData, setBarChartData] = useState([]);
  // const merchantDetails = useSelector((state) => state?.user?.user);

  const { data: dashboardKey, refetch } = useDashboardKeyQuery(undefined, {
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  });
  const DashboardKEY = dashboardKey?.data?.api_key;

  const DashboardPIData = async () => {
    try {
      const payload = {};
      const enc = await encryption(JSON.stringify(payload));
      const securedData = { dataString: enc };
      const response = await dashboardPIChartData(securedData).unwrap();

      if (response?.status && response.data) {
        const transformedData = response.data?.map((item) => {
          // Define the coin family symbol text
          const coinFamilySymbol =
            item?.is_token === 1 && item?.coin_family
              ? coinFamilySymbols[item?.coin_family]
              : "";

          // Create the label as a string
          const label = `${item?.coin_symbol?.toUpperCase()}${
            item?.token_type ? ` (${item.token_type})` : ""
          } ${coinFamilySymbol}`;

          return {
            label, // Now it's a string
            value: item?.total_amount,
            color: item?.pie_chart_color_code,
          };
        });

        setPichartData(transformedData);
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  const DashboardBarData = async () => {
    try {
      const payload = {
        period: filter,
        timeZone,
        coinId: selectedAsset.id,
      };
      const enc = await encryption(JSON.stringify(payload));
      const securedData = { dataString: enc };
      const response = await dashboardBarData(securedData).unwrap();
      if (response?.status && response.data) {
        setBarChartData(response?.data);
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  const chartData = barChartData?.map((item) => {
    switch (filter) {
      case "1D":
        return {
          hour: new Date(item.hour_interval).getHours() + ":00", // Extract the hour from the timestamp
          Amount: item?.amount * item?.current_price,
        };
      case "1W":
        return {
          hour: item?.day_name, // Use day name for weekly data
          Amount: item?.amount * item?.current_price,
        };
      case "1M":
        return {
          hour: item?.date_interval, // Use date for monthly data
          Amount: item?.amount * item?.current_price,
        };
      case "1Y":
        return {
          hour: item?.month_name, // Use month name for yearly data
          Amount: item?.amount * item?.current_price,
        };
      default:
        return {}; // Return an empty object if no valid filter
    }
  });

  useEffect(() => {
    DashboardPIData();
  }, []);

  useEffect(() => {
    setBarChartData([]);
    DashboardBarData();
  }, [filter, selectedAsset]);

  const DashboardEarnedPayout = async () => {
    try {
      const payload = {
        type: "earned_payouts",
        timeline: earnPay,
        coinId: earnedPayoutsAsset.id,
      };
      const enc = await encryption(JSON.stringify(payload));
      const securedData = { dataString: enc };
      const response = await DashboardData(securedData).unwrap();
      const earnedPayouts = response.data;
      setEarnedPayouts(earnedPayouts); // Update state here
    } catch (error) {
      handleApiError(error);
    }
  };

  const DashboardVolume = async () => {
    try {
      const payload = {
        type: "payment_volume",
        coinId: userAmountAsset.id,
        timeline: volPayments,
      };
      const enc = await encryption(JSON.stringify(payload));
      const securedData = { dataString: enc };
      const response = await DashboardData(securedData).unwrap();
      const resData = response?.data;
      const cryptoVolume =
        resData?.total_crypto_amount !== null && resData?.coin_data !== null
          ? exponentialToDecimal(
              formatNumber(
                resData.total_crypto_amount,
                resData.coin_data?.decimal_value
              )
            )
          : "0";
      const fiatVolume = resData?.coin_data
        ? `$${(
            resData?.total_crypto_amount *
            resData?.coin_data?.coin_price_in_fiat_data?.current_price
          ).toFixed(4)}`
        : "$0";
      const volume = `${cryptoVolume}(${fiatVolume})`;

      setUserPaymentVolume(volume); // Update state here
    } catch (error) {
      handleApiError(error);
    }
  };

  useEffect(() => {
    DashboardVolume();
  }, [volPayments, userAmountAsset]);

  useEffect(() => {
    DashboardEarnedPayout();
  }, [earnPay, earnedPayoutsAsset]);

  useEffect(() => {
    refetchAssets();
  }, []);

  const menuProps = {
    items,
  };
  const merchantsData = [
    {
      count:
        earnedPayouts && earnedPayouts?.coin_data
          ? `${exponentialToDecimal(
              formatNumber(
                earnedPayouts.total_crypto_amount,
                earnedPayouts?.coin_data?.decimal_value
              )
            )}($${(
              (earnedPayouts.total_crypto_amount || 0) *
              (earnedPayouts?.coin_data?.coin_price_in_fiat_data
                ?.current_price || 0)
            ).toFixed(4)})`
          : "0($0)",
      label: "Earned Payouts",
      icon: <EarnedPayoutsIcon />,
      key: "earnedPayouts",
      filterType: "earned",
      hasDropdown: true,
    },
    {
      count: userPaymentVolume,
      label: "Volume of user payments",
      icon: <DollerSwapIcon />,
      hasDropdown: true,
      key: "userAmount",
      filterType: "vols",
    },
  ];

  useEffect(() => {
    if (assetsData && assetsData.status === true) {
      const formattedItems = assetsData?.data?.map((asset) => ({
        label: (
          <p
            onClick={() =>
              setSelectedAsset({
                id: asset.id,
                image:
                  asset?.coin_image != null
                    ? `${CONFIG.S3_URL}/${asset?.coin_image}`
                    : `${noImage}`,
                name: asset.coin_name,
                isToken: asset?.is_token,
              })
            }
          >
            <img
              src={
                asset.coin_image != null
                  ? `${CONFIG.S3_URL}/${asset.coin_image}`
                  : `${noImage}`
              }
              alt={`${asset.coin_name} icon`}
              height={20}
              width={20}
            />
            {asset?.coin_name}
            <small>
              {asset?.is_token === 1 && asset?.coin_family ? (
                <span className="coin-symbol">
                  ({coinFamilySymbols[asset?.coin_family]})
                </span>
              ) : null}
            </small>
          </p>
        ),
        key: asset.id,
      }));
      setItems(formattedItems);
    }
  }, [assetsData]);

  const handleFilterChange = (newFilter, type) => {
    if (type === "earned") {
      setEarnPay(newFilter);
    } else if (type === "paymentBar") {
      setFilter(newFilter);
    } else if (type === "vols") {
      setVolPayments(newFilter);
    }
  };
  const earnedPayoutsItems = assetsData?.data?.map((asset) => ({
    label: (
      <p
        onClick={() =>
          setEarnedPayoutsAsset({
            id: asset.id,
            image: asset?.coin_image
              ? `${CONFIG.S3_URL}/${asset.coin_image}`
              : `${noImage}`,
            name: asset.coin_name,
          })
        }
      >
        <img
          src={
            asset.coin_image
              ? `${CONFIG.S3_URL}/${asset.coin_image}`
              : `${noImage}`
          }
          alt={`${asset.coin_name} icon`}
          height={20}
          width={20}
        />
        {asset.coin_name}
        <small>
          {asset?.is_token === 1 && asset?.coin_family ? (
            <span className="coin-symbol">
              ({coinFamilySymbols[asset?.coin_family]})
            </span>
          ) : null}
        </small>
      </p>
    ),
    key: asset.id,
  }));

  const userAmountItems = assetsData?.data?.map((asset) => ({
    label: (
      <p
        onClick={() =>
          setUserAmountAsset({
            id: asset.id,
            image: asset?.coin_image
              ? `${CONFIG.S3_URL}/${asset.coin_image}`
              : `${noImage}`,
            name: asset.coin_name,
          })
        }
      >
        <img
          src={
            asset.coin_image
              ? `${CONFIG.S3_URL}/${asset.coin_image}`
              : `${noImage}`
          }
          alt={`${asset.coin_name} icon`}
          height={20}
          width={20}
        />
        {asset.coin_name}
        <small>
          {asset?.is_token === 1 && asset?.coin_family ? (
            <span className="coin-symbol">
              ({coinFamilySymbols[asset?.coin_family]})
            </span>
          ) : null}
        </small>
      </p>
    ),
    key: asset.id,
  }));

  return (
    <div className="dashboard">
      <Flex justify="center" gap={24} wrap className="merchantsCard">
        {merchantsData?.map((data, index) => (
          <div key={index} className="merchantsCard_items commonCardBG">
            <div className="commonMonthBtn">
              {["1D", "1W", "1M", "1Y"].map((period) => (
                <button
                  key={period}
                  className={`filter-button ${
                    earnPay === period && data.filterType === "earned"
                      ? "active"
                      : ""
                  } ${
                    volPayments === period && data.filterType === "vols"
                      ? "active"
                      : ""
                  }`}
                  onClick={() => handleFilterChange(period, data.filterType)}
                >
                  {period}
                </button>
              ))}
            </div>
            <Flex align="center" style={{ height: "100%" }} gap={24}>
              <div className="merchantsCard_icon">{data.icon}</div>
              <div className="merchantsCard_detail">
                <Flex gap={10} align="center">
                  {data.hasDropdown && (
                    <Dropdown
                      menu={{
                        items:
                          data.key === "earnedPayouts"
                            ? earnedPayoutsItems
                            : userAmountItems,
                      }}
                      className="customDrop"
                    >
                      <Button>
                        <img
                          src={
                            data.key === "earnedPayouts"
                              ? earnedPayoutsAsset.image
                              : userAmountAsset.image
                          }
                          alt=""
                          style={{
                            height: "20px",
                            width: "20px",
                          }}
                        />
                        <DownOutlined />
                      </Button>
                    </Dropdown>
                  )}
                  <h3>{data.count}</h3>
                </Flex>
                <p>{data.label}</p>
              </div>
            </Flex>
          </div>
        ))}
        <div className="merchantsCard_items commonCardBG">
          <div className="merchantsCard_kybStatus">
            <p>KYB Status:</p>
            <h6>
              {dashboardKey?.data?.status === 1
                ? "Approved"
                : dashboardKey?.data?.status === 2
                ? "Rejected"
                : "Pending"}
            </h6>
          </div>
          <div className="greenDivBg">
            <p>
              API Key : {formatApiKey(DashboardKEY) || "N/A"}
              <span
                onClick={() => {
                  handleCopy(DashboardKEY);
                }}
                style={{ marginLeft: "10px", cursor: "pointer" }} // Add margin for spacing
              >
                <CopyIcon />
              </span>
            </p>
            <span></span>
          </div>
        </div>
      </Flex>

      <div className="dashboard_graph">
        <div className="merchantPayment">
          <div className="merchantPayment_head">
            <h3>User Payment Summary</h3>
            <Flex>
              <Dropdown menu={menuProps} className="customDrop">
                <Button>
                  {selectedAsset ? (
                    <img
                      src={selectedAsset.image}
                      alt=""
                      height={20}
                      width={20}
                    />
                  ) : (
                    ""
                  )}
                  {/* {selectedAsset?.name || "Filter By Asset"} */}
                  <DownOutlined />
                </Button>
              </Dropdown>
              <div className="commonMonthBtn">
                {["1D", "1W", "1M", "1Y"].map((period) => (
                  <button
                    key={period}
                    className={`filter-button ${
                      filter === period ? "active" : ""
                    }`}
                    onClick={() => handleFilterChange(period, "paymentBar")}
                  >
                    {period}
                  </button>
                ))}
              </div>
            </Flex>
          </div>
          <div className="dashboard_graph_section">
            {barChartData?.length > 0 ? (
              <BarChart width={700} height={300} data={chartData}>
                <XAxis dataKey="hour">
                  <Label
                    value="Timeline"
                    offset={-15}
                    position="insideBottom"
                  />
                </XAxis>
                <YAxis>
                  <Label
                    value="Amount in $"
                    angle={-90}
                    position="insideLeft"
                    // offset={-2}
                  />
                </YAxis>
                <Tooltip />
                <Legend />
                <Bar dataKey="Amount" fill="#FFC107" />
              </BarChart>
            ) : (
              <NoData />
            )}
          </div>
        </div>

        <div className="revenueChart">
          <div className="revenueChart_head">
            <h3>User Payment Summary</h3>
          </div>

          <div className="dashboard_graph_section">
            <Box sx={{ width: "100%" }}>
              {pichartData?.every((item) => item?.value === 0) ? (
                // <p>No data to display</p> // Display message if all values are zero
                <PieChart
                  height={300}
                  series={[
                    {
                      data: dummypichartData?.map((item) => ({
                        ...item,
                        itemStyle: { color: "#000000" },
                      })),
                      innerRadius: 100,
                      arcLabel: () => "",
                      arcLabelMinAngle: 20,
                    },
                  ]}
                  tooltip={{
                    show: false,
                    formatter: () => "No data", // Show 'No data' on hover
                  }}
                />
              ) : (
                <PieChart
                  height={300}
                  width={600}
                  series={[
                    {
                      data: pichartData?.map((item) => ({
                        ...item,
                        itemStyle: { color: item.color },
                      })),
                      valueFormatter: (item, { dataIndex }) => {
                        return `${item.value}`;
                      },
                      innerRadius: 100,
                      // arcLabel: (params) => params.label ?? "",
                      arcLabelMinAngle: 20,
                    },
                  ]}
                />
              )}
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
