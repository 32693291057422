import React, { useEffect, useState } from "react";
import { DatePicker, Table } from "antd";
import "./PaymentDetails.scss";
import { ButtonCustom } from "../../Ui/button/ButtonCustom";
import { DownloadIcon } from "../../../Assets/Assets";
import { usePaymentTableMutation } from "../../../Redux/apiService/paymentApi";
import { encryption } from "../../Commons/commonFunctions/encryption";
import useApiErrorHandler from "../../Commons/customHooks/useApiErrorHandler";
import { Path } from "../../../Routing/Constant/RoutePaths";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { CONFIG } from "../../Commons/constants/constant";
import { CalendarFilled } from "@ant-design/icons";
import { coinFamilySymbols } from "../../Commons/commonFunctions/commonFunctions";
import { formatNumber, exponentialToDecimal } from "../../Commons/commonFunctions/commonFunctions";

function OutstandingRequests({ trigger }) {
  const [PaymentTable] = usePaymentTableMutation();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [tableData, setTableData] = useState([]);
  const { handleApiError } = useApiErrorHandler();

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const { RangePicker } = DatePicker;

  const location = useLocation();
  const currentLocation = location.pathname;
  const searchBarValue = useSelector((state) => state.search.searchValue);
  const merchantDetails = useSelector((state) => state?.user?.user);
  const timeZone = useSelector((state) => state?.user?.time_zone);

  const fetchData = async (resetPage = false) => {
    try {
      let pageToFetch = resetPage ? 1 : pagination.current;
      const payload = {
        page: pageToFetch,
        limit: pagination?.pageSize,
      };
      if (startDate && endDate) {
        payload.startDate = startDate;
        payload.endDate = endDate;
      }
      if (currentLocation === Path.PAYMENTDETAILS && searchBarValue !== "") {
        payload.search = searchBarValue;
      }

      const enc = await encryption(JSON.stringify(payload));
      const securedData = { dataString: enc };
      const response = await PaymentTable(securedData).unwrap();
      const balanceData = response?.data;

      if (Array.isArray(balanceData)) {
        const transformedData = balanceData?.map((item) => ({
          Date: item.created_at
            ? `${new Date(item?.created_at)?.toLocaleDateString("en-US", {
              year: "numeric",
              month: "short",
              day: "2-digit",
            })} | ${new Date(item?.created_at)?.toLocaleTimeString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            })}`
            : "N/A",
          Status:
            item?.status === 2
              ? "Rejected"
              : item.status === 3
                ? "Approved"
                : "Pending",
          RequestedAmount:
            item?.request_amount_in_crypto !== undefined
              ? exponentialToDecimal(formatNumber(item.request_amount_in_crypto, item?.coin_data?.decimal_value))
              : "N/A",
          key: "N/A",
          name: "N/A",
          RequestedCurrency:
            item?.coin_data?.is_token === 1 && item?.coin_data?.coin_symbol
              ? `${item?.coin_data?.coin_symbol?.toUpperCase()} (${coinFamilySymbols[item?.coin_data?.coin_family] ?? ""
              })`
              : item?.coin_data?.coin_symbol.toUpperCase() ?? "N/A",
        }));
        setTableData(transformedData);
        setPagination({
          ...pagination,
          current: response?.meta?.page,
          total: response?.meta?.total || 0,
        });
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  const handleTableChange = (pagination) => {
    setPagination({
      ...pagination,
      current: pagination?.current,
    });
  };
  useEffect(() => {
    fetchData(false);
  }, [endDate, startDate, trigger, pagination?.current]);

  useEffect(() => {
    setPagination((prev) => ({ ...prev, current: 1 }));
    fetchData(true);
  }, [searchBarValue]);

  const onChange = (dates, dateStrings) => {
    setStartDate(dateStrings[0]);
    setEndDate(dateStrings[1]);
  };

  const downoaldList = () => {
    const baseUrl = `${CONFIG.API_URL}paymentDetails/downloadOutstandingRequests`;
    const params = new URLSearchParams({
      page: pagination?.current,
      limit: pagination?.pageSize,
      merchantId: merchantDetails?.id,
    });
    // const params = new URLSearchParams({
    //   page: pagination?.current,
    //   limit: pagination?.pageSize,
    // });

    if (timeZone) {
      params.append("timeZone", timeZone);
    }
    if (currentLocation === Path.PAYMENTDETAILS && searchBarValue !== "") {
      params.append("search", searchBarValue);
    }
    if (startDate) {
      params.append("startDate", startDate);
    }
    if (endDate) {
      params.append("endDate", endDate);
    }
    const link = `${baseUrl}?${params.toString()}`;

    window.open(link);
  };

  const columns = [
    {
      title: "S.No",
      dataIndex: "key",
      key: "key",
      fixed: "left",
      render: (_, __, index) =>
        (pagination.current - 1) * pagination.pageSize + index + 1,
    },

    {
      title: "Date of Request",
      dataIndex: "Date",
      key: "Date",
    },
    {
      title: "Requested Currency",
      dataIndex: "RequestedCurrency",
      key: "RequestedCurrency",
    },

    {
      title: "Requested Amount",
      dataIndex: "RequestedAmount",
      key: "RequestedAmount",
    },

    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
      render: (status) => {
        let color = "";
        if (status === "Pending") {
          color = "#FFD700";
        } else if (status === "Rejected") {
          color = "red";
        } else if (status === "Approved") {
          color = "green";
        }
        return <span style={{ color }}>{status}</span>;
      },
    },
  ];

  return (
    <div className="completedRequests">
      <div className="completedRequests_top">
        <div className="completedRequests_top">
          <RangePicker
            onChange={onChange}
            suffixIcon={<CalendarFilled style={{ color: "black" }} />}
          />
        </div>
        <ButtonCustom
          leftIcon={<DownloadIcon />}
          label="Download CSV"
          className="downloadBtn"
          onClick={downoaldList}
          disabled={tableData?.length < 1}
        />
      </div>
      <div className="UserTable">
        <Table
          dataSource={tableData}
          columns={columns}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
          }}
          onChange={handleTableChange}
        />
      </div>
    </div>
  );
}

export default OutstandingRequests;
