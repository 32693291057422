import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  google2fa_status: 0,
  time_zone: '',
  publicKeyCrypto: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      localStorage.removeItem("jwt_token");
      localStorage.removeItem("google2fa_status");
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    set2FaStatus: (state, action) => {
      state.google2fa_status = action.payload;
    },
    setTimeZone: (state, action) => {
      state.time_zone = action.payload;
    },
    setPublicKeyCrypto: (state, action) => {
      state.publicKeyCrypto = action.payload; 
    },

  },
});

export default userSlice.reducer;

export const { logout, setUser, set2FaStatus, setTimeZone,setPublicKeyCrypto } = userSlice.actions;
