export const Path = {
  HOME: "/",
  LOGIN: "/login",
  FORGOTPASSWORD: "/forgotPassword",
  DASHBOARD: "/dashboard",
  TRANSACTIONS: "/transactions",
  PAYMENTDETAILS: "/payment-Details",
  PREFERENCES: "/preferences",
  MERCHANTCONFIG: "/merchantConfig",
  SETTINGS: "/settings",
  NEWMERCHANT: "/newmerchant",
};
