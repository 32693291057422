import React from 'react';
import '././NoData.scss'; 
import notFound from "../../../Assets/notfound.jpeg"

function NoData({ message = "No Data Available", icon ,className}) {
  return (
    <div className={`no-data-container ${className}`}>
      {icon && <div className="no-data-icon"><img src={notFound} alt="" /></div>}
      <p className="no-data-message">{message}</p>
    </div>
  );
};

export default NoData;

