import { message } from "antd";
import Web3 from "web3";

export const formatWalletAddress = (address) => {
  if (!address) return ""; // Handle cases where the address might be undefined or null
  const start = address.slice(0, 10); // First 10 characters
  const end = address.slice(-5); // Last 5 characters
  return `${start}...${end}`;
};

export const formatApiKey = (key) => {
  if (!key) return ""; // Handle cases where the address might be undefined or null
  const start = key.slice(0, 15); // First 10 characters
  const end = key.slice(-10); // Last 5 characters
  return `${start}...${end}`;
};

export const handleCopy = (walletAddress) => {
  navigator.clipboard
    .writeText(walletAddress)
    .then(() => {
      message.success("Copied to clipboard!"); // Success message
    })
    .catch(() => {
      message.error("Failed to copy! "); // Error message
    });
};

export const isValidAddress = (adr) => {
  try {
    const web3 = new Web3();
    web3.utils.toChecksumAddress(adr);
    return true;
  } catch (e) {
    return false;
  }
};

export const firstUpperCase = (word) => {
  return word
    .split(" ")
    .map((sentence) => {
      return sentence.charAt(0).toUpperCase() + sentence.slice(1);
    })
    .join(" ");
};

export const calculateTimeAgo = (date) => {
  const now = new Date();
  const updatedAt = new Date(date);
  const diffInSeconds = Math.floor((now - updatedAt) / 1000);

  if (diffInSeconds < 60) {
    return `${diffInSeconds} ${diffInSeconds > 1 ? "seconds" : "second"} ago`;
  } else if (diffInSeconds < 3600) {
    const minutes = Math.floor(diffInSeconds / 60);
    return `${minutes} ${minutes > 1 ? "minutes" : "minute"} ago`;
  } else if (diffInSeconds < 86400) {
    const hours = Math.floor(diffInSeconds / 3600);
    return `${hours} ${hours > 1 ? "hours" : "hour"} ago`;
  } else {
    const days = Math.floor(diffInSeconds / 86400);
    return `${days} ${days > 1 ? "days" : "day"} ago`;
  }
};

export const coinFamilySymbols = {
  1: "BTC",
  2: "ETH",
  3: "BNB",
  4: "POL",
  5: "TRX",
};

export function formatNumber(value, decimalPlaces) {
  const factor = Math.pow(10, decimalPlaces);
  console.log("value and decimals", value, decimalPlaces);
  return Math.floor(value * factor) / factor;
}


export function exponentialToDecimal(exponential) {
  let decimal = exponential.toString().toLowerCase();
  if (decimal.includes("e+")) {
    const exponentialSplitted = decimal.split("e+");
    let postfix = "";
    for (
      let i = 0;
      i <
      +exponentialSplitted[1] -
        (exponentialSplitted[0].includes(".")
          ? exponentialSplitted[0].split(".")[1].length
          : 0);
      i++
    ) {
      postfix += "0";
    }
    const addCommas = (text) => {
      let j = 3;
      let textLength = text.length;
      while (j < textLength) {
        text = `${text.slice(0, textLength - j)}${text.slice(
          textLength - j,
          textLength
        )}`;
        textLength++;
        j += 3 + 1;
      }
      return text;
    };
    decimal = addCommas(exponentialSplitted[0].replace(".", "") + postfix);
  }
  if (decimal.toLowerCase().includes("e-")) {
    const exponentialSplitted = decimal.split("e-");
    let prefix = "0.";
    for (let i = 0; i < +exponentialSplitted[1] - 1; i++) {
      prefix += "0";
    }
    decimal = prefix + exponentialSplitted[0].replace(".", "");
  }
  return decimal;
}