import { message } from "antd";
import { logout } from "../../../Redux/slices/userSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const useApiErrorHandler = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleApiError = (err) => {
    console.log("API HANDLER ERROR :: ", err);
    message.destroy();

    if (err?.status === "FETCH_ERROR") {
      message.error("Please check your internet connection");
    } else if (err?.data?.message === "Invalid token address.") {
      dispatch(logout());
      message.error("Session Timeout!");
      navigate("/login");
    } else if (err?.status === 401) {
      dispatch(logout());
      message.error("Session Timeout!");
      navigate("/login");
    } else if (err?.data?.message) {
      message.error(err?.data?.message);
    } else {
      console.error("API Error:", err);
    }
  };

  return { handleApiError };
};

export default useApiErrorHandler;
