import React, { useEffect, useState } from "react";
import "./MerchantDetails.scss";
import { CopyIcon, RightArrow } from "../../../Assets/Assets";
import Tether from "../../../Assets/TetherIcon.svg";
import Eth from "../../../Assets/Ethereum.svg";
import { Col, Flex, Row, Space, Switch, message } from "antd";
import CustomSelect from "../../Ui/CustomSelect/CustomSelect";
import InputCustom from "../../Ui/input/InputCustom";
import { ButtonCustom } from "../../Ui/button/ButtonCustom";
import { Modal } from "antd";
import {
  useAssetsDropdownListQuery,
  useEnableDisableCoinsMutation,
  useMerchantAddressWhitelistMutation,
  useWithdrawWalletListQuery,
  useMerchantConfigbalancesMutation,
  useSendFundRequestMutation,
} from "../../../Redux/apiService/merchantConfigApi";
import { CONFIG } from "../../Commons/constants/constant";
import useApiErrorHandler from "../../Commons/customHooks/useApiErrorHandler";
import { encryption } from "../../Commons/commonFunctions/encryption";
import { exponentialToDecimal } from "../../Commons/commonFunctions/commonFunctions";
import {
  calculateTimeAgo,
  coinFamilySymbols,
  formatNumber,
  formatWalletAddress,
  handleCopy,
  isValidAddress,
} from "../../Commons/commonFunctions/commonFunctions";
import noImage from "./../../../Assets/noImage.png";
import NoData from "../../Commons/noData/NoData";

function MerchantConfiguration() {
  const { data: assetsData, refetch: refetchAssets } =
    useAssetsDropdownListQuery();
  const { data: withdrawalWalletData, refetch } = useWithdrawWalletListQuery();
  const [whiteListNewWithdrawalAddres] = useMerchantAddressWhitelistMutation();
  // const [enableDisableCoins] = useEnableDisableCoinsMutation();
  const [MerchantConfigbalances] = useMerchantConfigbalancesMutation();
  const [SendFundRequest] = useSendFundRequestMutation();
  const { handleApiError } = useApiErrorHandler();

  const [items, setItems] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [dropdownData, setDropdownData] = useState([]);
  const [trackbalance, setTrackbalance] = useState({});
  const [payoutCardData, setPayoutCardData] = useState([]);
  const [timeAgo, setTimeAgo] = useState(""); // State to store the formatted time ago
  const [addresses, setAddresses] = useState([]);
  const [walletAddress, setWalletAddress] = useState("");
  const [requestWithdrawal, setrequestWithdrawal] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [amount, setAmount] = useState("");

  useEffect(() => {
    if (withdrawalWalletData && withdrawalWalletData?.status === true) {
      // Format payout cards
      const formattedPayoutCards =
        withdrawalWalletData?.data?.merchant_coin_data?.map((wallet) => ({
          imgSrc: wallet?.coin_data?.coin_image
            ? `${CONFIG.S3_URL}/${wallet?.coin_data?.coin_image}`
            : noImage,
          currencyName: `${wallet?.coin_data?.coin_name
            } (${wallet?.coin_data?.coin_symbol?.toUpperCase()}) ${wallet?.coin_data?.is_token === 0
              ? ""
              : coinFamilySymbols[wallet?.coin_data?.coin_family]
            }`,

          id: wallet?.coin_id,
          merchant_withdraw_coin_status: wallet?.merchant_withdraw_coin_status,
          coinFamily: wallet?.coin_data?.coin_family,
          isToken: wallet?.coin_data?.is_token,
        }));
      setPayoutCardData(formattedPayoutCards);
      // const updatedTime =
      //   withdrawalWalletData?.data?.merchant_coin_data[0]?.wallet_address_data
      //     ?.updated_at;
      // setTimeAgo(calculateTimeAgo(updatedTime)); // Calculate time ago for display
      const updatedTime =
        withdrawalWalletData?.data?.merchant_coin_data[0]
          ?.wallet_address_data != null
          ? withdrawalWalletData?.data?.merchant_coin_data[0]
            ?.wallet_address_data?.updated_at
          : null;
      if (updatedTime) {
        setTimeAgo(calculateTimeAgo(updatedTime));
      }

      // Format addresses
      const formattedAddresses =
        withdrawalWalletData?.data?.merchant_coin_data?.map((wallet) => ({
          id: wallet?.coin_id,
          label: `${wallet?.coin_data?.coin_name
            } (${wallet?.coin_data?.coin_symbol?.toUpperCase()}) ${wallet?.coin_data?.is_token === 0
              ? ""
              : coinFamilySymbols[wallet?.coin_data?.coin_family]
            }`,
          icon: wallet.coin_data?.coin_image
            ? `${CONFIG.S3_URL}/${wallet?.coin_data.coin_image}`
            : noImage,
          address: wallet?.wallet_address_data?.wallet_address || null,
        }));
      setAddresses(formattedAddresses);
    }
  }, [withdrawalWalletData]);

  useEffect(() => {
    if (assetsData && assetsData.status === true) {
      const formattedItems = assetsData?.data?.map((asset) => ({
        label: (
          <div
            onClick={() =>
              setSelectedAsset({
                // id: asset.id,
                image:
                  asset?.native_coin_data?.coin_image != null
                    ? `${CONFIG.S3_URL}/${asset?.native_coin_data?.coin_image}`
                    : `${noImage}`,
                name: asset?.native_coin_data?.coin_name,
                coin_family: asset?.native_coin_data?.coin_family,
              })
            }
          >
            <img
              src={
                asset?.native_coin_data?.coin_image != null
                  ? `${CONFIG.S3_URL}/${asset?.native_coin_data?.coin_image}`
                  : `${noImage}`
              }
              alt={`${asset?.native_coin_data?.coin_name}`}
              height={20}
              width={20}
            />
            {asset?.native_coin_data?.coin_name}
          </div>
        ),
        key: asset?.native_coin_data?.id,
      }));
      setItems(formattedItems);
    }
  }, [assetsData]);
  useEffect(() => {
    refetchAssets();
    refetch();
  }, [refetchAssets, refetch]);

  const menuProps = {
    items,
  };

  const merchantConfigbalances = async () => {
    try {
      const response = await MerchantConfigbalances().unwrap();

      if (response?.status === true) {
        // Format the payout cards
        const formattedPayoutCards = response?.data?.merchant_coin_data?.map(
          (coin) => ({
            imgSrc: coin?.coin_data?.coin_image
              ? `${CONFIG.S3_URL}/${coin?.coin_data?.coin_image}`
              : noImage,
            currencyName: `${coin?.coin_data?.coin_name
              } (${coin?.coin_data?.coin_symbol?.toUpperCase()}) ${coin?.coin_data?.is_token === 0
                ? ""
                : coinFamilySymbols[coin?.coin_data?.coin_family]
              }`,
            totalAmount: coin?.total_amount,
            totalAmountInUSD: coin?.total_amount_in_usd,
            coinFamily: coin?.coin_data?.coin_family,
            isToken: coin?.coin_data?.is_token,
            tokenAddress: coin?.coin_data?.token_address || null,
            id: coin?.coin_data?.id,
            decimalPlaces: coin?.coin_data?.decimal_value,
          })
        );
        setrequestWithdrawal(formattedPayoutCards);

        // You can also calculate any additional data here, like timeAgo or other parameters, if required.
      } else {
        console.log("No data available");
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  useEffect(() => {
    merchantConfigbalances();
  }, []);

  useEffect(() => {
    const balanceProps = requestWithdrawal
      ? requestWithdrawal.map((coin) => ({
        label: (
          <div
            onClick={() =>
              setTrackbalance({
                label: (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }} // Adding cursor style for better UX
                  >
                    <img
                      src={coin?.imgSrc || trackbalance?.imgSrc}
                      alt={coin?.currencyName || trackbalance?.currencyName}
                      width={20}
                      height={20}
                      style={{ marginRight: "8px" }}
                    />
                    <span>
                      {coin?.currencyName || trackbalance?.currencyName}
                    </span>
                  </div>
                ),
                key: coin?.id || trackbalance?.id || "",
                totalAmount: coin?.totalAmount,
              })
            }
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }} // Adding cursor style for better UX
          >
            <img
              src={coin?.imgSrc || trackbalance?.imgSrc}
              alt={coin?.currencyName || trackbalance?.currencyName}
              width={20}
              height={20}
              style={{ marginRight: "8px" }}
            />
            <span>{coin?.currencyName || trackbalance?.currencyName}</span>
          </div>
        ),
        key: coin?.id || trackbalance?.id || "",
      }))
      : [];

    setDropdownData(balanceProps);
  }, [requestWithdrawal]);

  const requestCoinData = {
    dropdownData,
  };
  const handleRequestClick = (item) => {
    setTrackbalance({
      label: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }} // Adding cursor style for better UX
        >
          <img
            src={item?.imgSrc || trackbalance?.imgSrc}
            alt={item?.currencyName || trackbalance?.currencyName}
            width={20}
            height={20}
            style={{ marginRight: "8px" }}
          />
          <span>{item?.currencyName || trackbalance?.currencyName}</span>
        </div>
      ),
      key: item?.id || trackbalance?.id || "",
      totalAmount:exponentialToDecimal(formatNumber(item?.totalAmount, item?.decimalPlaces)),
    });
    trackbalance && setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setTrackbalance({});
    setAmount("");
    setIsModalVisible(false);
  };

  const whiteListWithdrawalAddres = async () => {
    if (!selectedAsset) {
      message.error("Please select network from dropdown");
      return;
    } else if (!walletAddress) {
      message.error("Please enter a wallet address.");
      return;
    }
    // else if (!isValidAddress(walletAddress)) {
    //   message.error("Please enter a valid wallet address");
    //   return;
    // }

    try {
      const payload = {
        coinFamily: selectedAsset?.coin_family, // Include this if required
        walletAddress,
      };
      const enc = await encryption(JSON.stringify(payload));
      const securedData = { dataString: enc };

      const response = await whiteListNewWithdrawalAddres(securedData).unwrap();
      message.success(response?.message);
      // setSelectedAsset(null);
      setWalletAddress("");
      refetchAssets();
      refetch();
    } catch (error) {
      handleApiError(error);
    }
  };

  // const handleEnableDisableCoins = async (status, card) => {
  //   try {
  //     let payloadData = {
  //       coinId: card?.id,
  //       status,
  //     };
  //     console.log("PAYLOAD DATA", payloadData);
  //     const enc = await encryption(JSON.stringify(payloadData));
  //     const securedData = { dataString: enc };
  //     const data = await enableDisableCoins(securedData).unwrap();
  //     if (data?.status === true) {
  //       message.success(data?.message);
  //       refetch();
  //     }
  //   } catch (error) {
  //     handleApiError(error);
  //   }
  // };
  // const onChangeToggle = (checked, card) => {
  //   console.log("CHECKED AND CARD DATA", checked, card);
  //   const status = checked ? 1 : 0;
  //   handleEnableDisableCoins(status, card);
  // };

  const handleAmountChange = (e) => {
    const value = e.target.value;
    const validValue = /^\d+(\.\d{0,18})?$/; // Allows any positive number with up to two decimal places

    // setAmount(event.target.value);
    if (validValue.test(value)) {
      setAmount(value);
    } else if (value === "") {
      setAmount(""); // Allow clearing the input
    }
  };

  const sendFunds = async () => {
    if (!amount) {
      message.error("Please enter Amount!");
      return;
    }
    const numericAmount = Number(amount);
    if (isNaN(numericAmount) || numericAmount <= 0) {
      message.error("Enter the valid Number");
      return;
    }

    if (numericAmount > trackbalance.totalAmount) {
      message.error(
        "Please enter an amount that is within your available balance"
      );
      return; // Prevent further execution if the amount exceeds the balance
    }
    try {
      const payload = {
        amount: numericAmount.toString(),
        coinId: trackbalance.key,
      };
      const enc = await encryption(JSON.stringify(payload));
      const securedData = { dataString: enc };
      const response = await SendFundRequest(securedData).unwrap();
      message.success(response?.message);
      setIsModalVisible(false);
      setAmount("");
      merchantConfigbalances();
    } catch (error) {
      handleApiError(error);
    }
  };
  return (
    <div className="merchantView">
      <div className="formcurrencyflex">
        {/* Left Section: Whitelist New Withdrawal Address */}
        <div className="firstdiv">
          <div className="currencyPreferences_head">
            <h2>Whitelist New Withdrawal Address</h2>
          </div>
          <div className="merchantView_middle_container commonCardBG">
            <div className="updateNetwork_Address">
              <div className="mb16">
                <CustomSelect
                  data={menuProps}
                  placeholder="Choose Network"
                  label="Select Network"
                  labelcustom="customLabelClass"
                  outPagesSelect={true}
                  innerPagesSelect={false}
                  CustomSelectStyle="customStyle"
                  style={{ width: "450px" }}
                />
              </div>
              <div className="mb16">
                <InputCustom
                  basicInput
                  inputCustum
                  label="Add Address"
                  placeholder="Enter Wallet Address"
                  maxLength={100}
                  name="walletAddress"
                  value={walletAddress}
                  onChange={(e) => setWalletAddress(e.target.value)}
                />
              </div>

              <ButtonCustom
                greenBtn
                large
                label="Update"
                onClick={whiteListWithdrawalAddres}
                disabled={items?.length === 0}
              />
            </div>
          </div>
        </div>

        {/* Right Section: Payout Currencies Withdrawal */}
        <div className="enabledPayout" style={{ flex: 1 }}>
          <div className="currencyPreferences_head">
            <h2>Payout Currencies (Withdrawal)</h2>
          </div>
          <div className="enabledPayout_card">
            {payoutCardData && payoutCardData.length > 0 ? (
              payoutCardData.map((card, index) => (
                <div
                  key={index}
                  className="enabledPayout_card_item commonCardBG"
                >
                  <div
                    className="enabledPayout_card_left"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "16px",
                    }}
                  >
                    <img
                      src={card.imgSrc}
                      alt={card.currencyName}
                      height={50}
                      width={50}
                    />
                    <h3>{card.currencyName}</h3>
                  </div>
                  <div>
                    {/* Uncomment this if you want to include the Switch component */}
                    {/* <Switch
                  checked={card?.merchant_withdraw_coin_status}
                  onChange={(checked) => onChangeToggle(checked, card)}
                /> */}
                  </div>
                </div>
              ))
            ) : (
              <NoData />
            )}
          </div>
        </div>
      </div>
      <div className="merchantView_middle">
        <div className="merchantView_middle_container commonCardBG">
          <div className="merchantView_middle_head">
            <h6>Withdrawal Wallet Addresses</h6>
            <p>{timeAgo ? `(Last updated ${timeAgo})` : null}</p>
          </div>
          {addresses ? (
            addresses.map((item) => (
              <div className="merchantView_middle_addresses" key={item.id}>
                <h4>
                  <img
                    src={item?.icon}
                    alt={`${item.label} icon`}
                    height={25}
                    width={25}
                  />
                  {item?.label}
                </h4>
                <span>
                  <p> {formatWalletAddress(item?.address)}</p>

                  {item?.address != null ? (
                    <span
                      onClick={() => {
                        handleCopy(item?.address);
                      }}
                    >
                      <CopyIcon />{" "}
                    </span>
                  ) : null}
                </span>
              </div>
            ))
          ) : (
            <NoData />
          )}
        </div>
        <div className="merchantView_middle_container commonCardBG">
          <div className="currencyPreferences_head requestHead">
            <h2>Request Withdrawal</h2>
          </div>
          {requestWithdrawal ? (
            requestWithdrawal.map((item) => (
              <div className="merchantView_middle_addresses" key={item.id}>
                <h4>
                  <img
                    src={item?.imgSrc}
                    alt={`${item.currencyName} icon`}
                    height={25}
                    width={25}
                  />
                  {item?.currencyName}
                </h4>
                <Flex align="center" gap={25}>
                  <p>{exponentialToDecimal(formatNumber(item?.totalAmount, item?.decimalPlaces))}</p>
                  <button
                    style={{
                      height: "40px",
                      width: "120px",
                      backgroundColor: "black",
                      color: "white",
                      border: "none",
                      borderRadius: "5px",
                      fontSize: "16px",
                      fontWeight: "500",
                      cursor:
                        item.totalAmount === 0 ? "not-allowed" : "pointer",
                      padding: "10px 20px",
                      transition: "background-color 0.3s ease",
                    }}
                    onClick={() => handleRequestClick(item)}
                    disabled={item.totalAmount === 0}
                  >
                    Request
                  </button>
                </Flex>
              </div>
            ))
          ) : (
            <NoData />
          )}
        </div>
      </div>

      <Modal
        open={isModalVisible}
        onOk={handleModalClose}
        onCancel={handleModalClose}
        footer={null}
        maskClosable={false}
        className="common-modal alertModal"
      >
        <div className="modal-content" style={{ padding: "20px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "16px",
            }}
          >
            <p
              style={{
                margin: 0,
                fontSize: "16px",
                fontWeight: 500,
                color: "var(--mainBlack)",
              }}
            >
              Select Asset
            </p>
            <span style={{ fontSize: "14px", color: "var(--mainBlack)" }}>
              Available Balance: {
                (trackbalance?.totalAmount || 0)
              }{" "}
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleCopy(trackbalance.totalAmount);
                }}
              >
                <CopyIcon />{" "}
              </span>
            </span>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "16px",
            }}
          ></div>
          <div className="alertModal_footer" style={{ display: "block" }}>
            <div style={{ marginBottom: "16px" }}>
              <CustomSelect
                data={requestCoinData}
                placeholder="Choose Asset"
                label="Select Network"
                labelcustom="customLabelClass"
                outPagesSelect={true}
                defaultData={trackbalance}
                // value={trackbalance.imgSrc}
                innerPagesSelect={false}
                CustomSelectStyle="customStyle"
              />
            </div>
            <div style={{ marginBottom: "16px" }}>
              <InputCustom
                basicInput
                inputCustum
                label="Amount"
                placeholder="Enter Amount"
                maxLength={100}
                name="Amount"
                value={amount} // Step 3: Bind the state value
                onChange={handleAmountChange}
              />
            </div>
            <div style={{ marginBottom: "16px" }}>
              <ButtonCustom
                greenBtn
                large
                label="Update"
                customClass="login_btn"
                disabled={items?.length === 0}
                onClick={sendFunds}
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default MerchantConfiguration;
