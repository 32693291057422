import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CONFIG } from "../../Components/Commons/constants/constant";

export const transactionApi = createApi({
  reducerPath: "transactionApi",
  baseQuery: fetchBaseQuery({
    baseUrl: CONFIG.API_URL,
    prepareHeaders: (headers) => {
      headers.set("authorization", localStorage.getItem("jwt_token") || "");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    fetchData: builder.mutation({
      query: (payload) => ({
        url: "txnHistory/list",
        method: "POST",
        body: payload,
      }),
    }),
    downloadCsv: builder.query({
      query: () => ({
        url: "txnHistory/download",
        method: "GET",
      }),
    }),
    paymentassets: builder.query({
      query: () => ({
        url: "paymentDetails/assets",
        method: "GET",
      }),
    }),
  }),
});

export const { useFetchDataMutation, useDownloadCsvQuery, usePaymentassetsQuery } = transactionApi;
export default transactionApi;
