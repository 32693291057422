import React, { useState, useEffect } from "react";
import "./CurrencyPreferences.scss";
import { Switch } from "antd";
import { CopyIcon, ValueIcon } from "../../../Assets/Assets";
import { Col, Row } from "antd";
import {
  useWalletListQuery,
  useUpdateCoinStatusMutation,
} from "../../../Redux/apiService/currencyPreferences";
import {
  coinFamilySymbols,
  formatNumber,
  formatWalletAddress,
  handleCopy,
  exponentialToDecimal
} from "../../Commons/commonFunctions/commonFunctions";
import { CONFIG } from "../../Commons/constants/constant";
import useApiErrorHandler from "../../Commons/customHooks/useApiErrorHandler";
import { encryption } from "../../Commons/commonFunctions/encryption";
import noImage from "./../../../Assets/noImage.png";
import NoData from "../../Commons/noData/NoData";

function CurrencyPreferences() {
  const { data: walletData, refetch } = useWalletListQuery(undefined, {
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  });

  const { handleApiError } = useApiErrorHandler();
  const [UpdateCoinStatus] = useUpdateCoinStatusMutation();

  const coinData = walletData?.data?.merchant_coin_data || [];

  const onChangeToggle = (checked, coin) => {
    const status = checked ? 1 : 0;
    handleEnableDisableCoins(status, coin);
  };

  const handleEnableDisableCoins = async (status, coin) => {
    try {
      let payloadData = {
        coinId: coin?.coin_id,
        status,
      };
      const enc = await encryption(JSON.stringify(payloadData));
      const securedData = { dataString: enc };
      const data = await UpdateCoinStatus(securedData).unwrap();
      if (data?.status === true) {
        refetch();
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <div className="currencyPreferences">
      <div className="currencyPreferences_head">
        {/* <h2>Deposit Wallet</h2> */}
      </div>
      <div className="depositCard">
        {coinData.length > 0 ? (
          coinData?.map((coin, index) => (
            <div className="depositCard_item commonCardBG">
              <div className="depositCard_head">
                <h4>Enable/Disable</h4>
                <Switch
                  checked={coin?.merchant_coin_status === 1} // Enable if status is 1, disable if 0
                  onChange={(checked) => onChangeToggle(checked, coin)}
                />
              </div>
              <div className="depositCard_bottom">
                <div className="depositCard_bottom_left">
                  <img
                    src={
                      coin.coin_data.coin_image != null
                        ? `${CONFIG.S3_URL}/${coin.coin_data.coin_image}`
                        : `${noImage}`
                    }
                    alt={coin.currencyName}
                    height={50}
                    width={50}
                  />
                  <div>
                    <h3>
                      {coin.coin_data.coin_name}
                      <small>
                        {coin?.coin_data?.is_token === 1 &&
                          coin?.coin_data?.coin_family ? (
                          <span className="coin-symbol">
                            {" "}
                            ({coinFamilySymbols[coin?.coin_data?.coin_family]})
                          </span>
                        ) : null}
                      </small>
                    </h3>
                    {coin?.wallet_address_data &&
                      coin?.wallet_address_data?.type === "deposit" && (
                        <span key={index}>
                          {formatWalletAddress(
                            coin.wallet_address_data.wallet_address
                          )}{" "}
                          <span
                            onClick={() =>
                              handleCopy(
                                coin.wallet_address_data.wallet_address
                              )
                            }
                          >
                            <CopyIcon />
                          </span>
                        </span>
                      )}
                  </div>
                </div>
                <div className="depositCard_bottom_right">
                  <h3>
                    {
                      coin?.total_amount !== undefined && coin?.coin_data?.decimal_value !== undefined ? (
                        exponentialToDecimal(
                          formatNumber(coin.total_amount,
                          coin.coin_data.decimal_value
                        ))
                      ) : (
                        "0.00"
                      )
                    }{" "}
                    <span style={{ whiteSpace: "nowrap" }}>
                      {coin?.coin_data?.coin_symbol?.toUpperCase()}{" "}
                    </span>
                  </h3>
                  <p>
                    <ValueIcon /> $
                    {(
                      coin?.total_amount *
                      coin?.coin_data?.coin_price_in_fiat_data?.current_price
                    ).toFixed(4)}
                  </p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <NoData icon={noImage} />
        )}
      </div>
    </div>
  );
}

export default CurrencyPreferences;
