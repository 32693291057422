import React, { useEffect, useState } from "react";
import { DatePicker, Table } from "antd";
import CustomDropdown from "../../Ui/CustomDropdown/CustomDropdown";
import "./PaymentDetails.scss";
import { ButtonCustom } from "../../Ui/button/ButtonCustom";
import { CopyIcon, DownloadIcon, HashIcon } from "../../../Assets/Assets";
import { useCompletedPaymentTableMutation } from "../../../Redux/apiService/paymentApi";
import { encryption } from "../../Commons/commonFunctions/encryption";
import useApiErrorHandler from "../../Commons/customHooks/useApiErrorHandler";
import { Path } from "../../../Routing/Constant/RoutePaths";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAssestQuery } from "../../../Redux/apiService/paymentApi";
import { CONFIG } from "../../Commons/constants/constant";
import { Dropdown, Button, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import noImage from "./../../../Assets/noImage.png";
import {
  coinFamilySymbols,
  formatWalletAddress,
  handleCopy,
  formatNumber,
  exponentialToDecimal
} from "../../Commons/commonFunctions/commonFunctions";
import { CalendarFilled } from "@ant-design/icons";
import dayjs from "dayjs";

function CompletedRequests() {
  const { data: assetsData, refetch } = useAssestQuery(undefined, {
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  });
  const { RangePicker } = DatePicker;
  const { handleApiError } = useApiErrorHandler();
  const [CompletedPaymentTable] = useCompletedPaymentTableMutation();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [items, setItems] = useState([]);
  const [tableData, settableData] = useState([]);
  const [coinId, setCoinID] = useState();
  const [loading , setLoading] = useState(false)
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,

  });
  const timeZone = useSelector((state) => state?.user?.time_zone);

  const onChange = (dates, dateStrings) => {
    setStartDate(dateStrings[0] || null);
    setEndDate(dateStrings[1] || null);
  };

  const merchantDetails = useSelector((state) => state?.user?.user);
  const location = useLocation();
  const currentLocation = location.pathname;
  const searchBarValue = useSelector((state) => state?.search?.searchValue);

  const fetchData = async (resetPage = false) => {
    setLoading(true);
    try {
      let pageToFetch = resetPage ? 1 : pagination.current;
      const payload = {
        page: pageToFetch,
        limit: pagination?.pageSize,
      };
      if (startDate && endDate) {
        payload.startDate = startDate;
        payload.endDate = endDate;
      }
      if (currentLocation === Path.PAYMENTDETAILS && searchBarValue !== "") {
        payload.search = searchBarValue;
      }
      if (selectedAsset) {
        payload.coinId = selectedAsset?.id;
      }
      await new Promise((resolve) => setTimeout(resolve, 500));

      const enc = await encryption(JSON.stringify(payload));
      const securedData = { dataString: enc };
      const response = await CompletedPaymentTable(securedData).unwrap();
      const completedData = response?.data;
      settableData(completedData);
      setPagination({
        ...pagination,
        current: response?.meta?.page,
        total: response?.meta?.total || 0,
      });
      setLoading(false)
    } catch (error) {
      handleApiError(error);
    }
  };

  useEffect(() => {
    setPagination((prev) => ({ ...prev, current: 1 }));
    fetchData(true);
  }, [searchBarValue , startDate , endDate]);

  const onPressView = (item) => {
    const { txn_hash } = item;
    const coinfamily = item?.coin_data?.coin_family;
    if (coinfamily == 2) {
      return window.open("https://etherscan.io/tx/" + txn_hash);
    } else if (coinfamily == 3) {
      return window.open("https://bscscan.com/tx/" + txn_hash);
    } else if (coinfamily == 1) {
      return window.open("https://live.blockcypher.com/btc/tx/" + txn_hash);
    } else if (coinfamily == 4) {
      return window.open("https://polygonscan.com/tx/" + txn_hash);
    } else if (coinfamily == 5) {
      return window.open("https://tronscan.org/#/transaction/" + txn_hash);
    }
  };

  const handleTableChange = (pagination) => {
    setPagination({
      ...pagination,
      current: pagination?.current,
    });
  };

  const handleAssetSelect = (asset) => {
    setSelectedAsset({
      id: asset.id,
      image:
        asset?.coin_image != null
          ? `${CONFIG.S3_URL}/${asset?.coin_image}`
          : `${noImage}`,
      isToken: asset?.is_token,
      name: asset.coin_name,
    });

    setPagination((prev) => ({ ...prev, current: 1 }));
  };
  useEffect(() => {
    fetchData();
  }, [ selectedAsset, pagination?.current]);

  useEffect(() => {
    if (assetsData && assetsData?.status === true) {
      const formattedItems = assetsData?.data.map((asset) => ({
        label: (
          <p onClick={() => handleAssetSelect(asset)}>
            <img
              src={
                asset.coin_image != null
                  ? `${CONFIG.S3_URL}/${asset.coin_image}`
                  : `${noImage}`
              }
              alt={`${asset.coin_name} icon`}
              height={20}
              width={20}
            />
            {asset?.coin_name}
            <small>
              {asset?.is_token === 1 && asset?.coin_family ? (
                <span className="coin-symbol">
                  ({coinFamilySymbols[asset?.coin_family]})
                </span>
              ) : null}
            </small>
          </p>
        ),
        key: asset.id,
      }));
      setItems(formattedItems);
    }
  }, [assetsData]);

  const menuProps = {
    items,
  };

  const downoaldList = () => {
    const baseUrl = `${CONFIG.API_URL}paymentDetails/downloadCompletedRequests`;
    const params = new URLSearchParams({
      page: pagination?.current,
      limit: pagination?.pageSize,
      merchantId: merchantDetails?.id,
    });
    if (timeZone) {
      params.append("timeZone", timeZone);
    }
    if (currentLocation === Path.PAYMENTDETAILS && searchBarValue !== "") {
      params.append("search", searchBarValue);
    }
    if (selectedAsset) {
      params.append("coinId", selectedAsset?.id);
    }

    if (startDate) {
      params.append("startDate", startDate);
    }
    if (endDate) {
      params.append("endDate", endDate);
    }
    const link = `${baseUrl}?${params.toString()}`;
    window.open(link);
  };

  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      key: "sno",
      render: (_, __, index) =>
        (pagination.current - 1) * pagination.pageSize + index + 1,
    },
    {
      title: "Date of Request",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => {
        if (!created_at) return "N/A"; // Handle case if no date is present

        const date = new Date(created_at);

        const formattedDate = date.toLocaleDateString("en-US", {
          month: "short",
          day: "2-digit",
          year: "numeric",
        });

        const formattedTime = date.toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });

        return `${formattedDate} | ${formattedTime}`;
      },
    },
    {
      title: "Date of Release",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (updated_at) => {
        if (!updated_at) return "N/A"; // Handle case if no date is present

        const date = new Date(updated_at);

        const formattedDate = date.toLocaleDateString("en-US", {
          month: "short",
          day: "2-digit",
          year: "numeric",
        });

        const formattedTime = date.toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });

        return `${formattedDate} | ${formattedTime}`;
      },
    },

    {
      title: "Merchant Payment",
      dataIndex: "amount_in_crypto",
      key: "amount_in_crypto",
      render: (_, item) => (
        <>
          {item?.amount_in_crypto ? (
            <span>{exponentialToDecimal(formatNumber(item?.amount_in_crypto, item?.coin_data?.decimal_value))}</span>
          ) : (
            <span>N/A</span>
          )}
        </>
      ),
    },

    {
      title: "Admin Commission ",
      dataIndex: "commission_fee_in_crypto",
      key: "commission_fee_in_crypto",
      render: (_, item) => (
        <>
          {item?.commission_fee_in_crypto ? (
            <span>{exponentialToDecimal(formatNumber(item?.commission_fee_in_crypto, item?.coin_data?.decimal_value))}</span>
          ) : (
            <span>N/A</span>
          )}
        </>
      ),
    },
    {
      title: "Payment Currency",
      dataIndex: "Currency",
      key: "Currency",
      render: (_, item) => (
        <p>
          {item?.coin_data?.coin_symbol?.toUpperCase()}{" "}
          {item?.coin_data?.is_token === 1 && item?.coin_data?.coin_family ? (
            <span className="coin-symbol">
              ({coinFamilySymbols[item?.coin_data?.coin_family]})
            </span>
          ) : null}
        </p>
      ),
    },
    {
      title: "Receiving Address",
      dataIndex: "to_address",
      key: "to_address",
      render: (to_address) => (
        <>
          {formatWalletAddress(to_address)}
          <span
            style={{ marginLeft: "8px", cursor: "pointer" }}
            onClick={() => handleCopy(to_address)}
          >
            <CopyIcon />
          </span>
        </>
      ),
    },
    {
      title: "Hash",
      dataIndex: "txn_hash",
      key: "txn_hash",
      render: (_, item) => (
        <>
          {formatWalletAddress(item.txn_hash)}
          {item?.txn_hash != null ? (
            <span
              style={{ marginLeft: "8px", cursor: "pointer" }}
              onClick={() => onPressView(item)}
            >
              {" "}
              <HashIcon />
            </span>
          ) : null}
        </>
      ),
    },
    // {
    //   title: "Merchant Payment ",
    //   dataIndex: "amount_in_fiat",
    //   key: "amount_in_fiat",
    // },
  ];

  const resetAllFilters = () => {
    setEndDate(null);
    setStartDate(null);
    setSelectedAsset(null);
  };
  return (
    <div className="completedRequests">
      <div className="completedRequests_top">
        <div>
          <RangePicker
            onChange={onChange}
            value={
              startDate && endDate ? [dayjs(startDate), dayjs(endDate)] : []
            }
            suffixIcon={<CalendarFilled style={{ color: "black" }} />}
          />
        </div>
        <div className="completedRequests_top">
          <Button onClick={resetAllFilters}>Reset All Filters</Button>

          <Dropdown menu={menuProps}>
            <Button>
              {selectedAsset ? (
                <img
                  src={selectedAsset.image}
                  alt={noImage}
                  height={20}
                  width={20}
                />
              ) : (
                ""
              )}
              {selectedAsset?.name || "Filter By Asset"}
              <DownOutlined />
            </Button>
          </Dropdown>
          <ButtonCustom
            leftIcon={<DownloadIcon />}
            label="Download CSV"
            className="downloadBtn"
            onClick={downoaldList}
            disabled={tableData?.length < 1}
          />
        </div>
      </div>
      <div className="UserTable">
        <Table
          dataSource={tableData}
          columns={columns}
          tableLayout={"auto"}
          loading={loading}
          scroll={{ x: "max-content" }}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
          }}
          onChange={handleTableChange}
        />
      </div>
    </div>
  );
}

export default CompletedRequests;
