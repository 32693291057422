import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CONFIG } from "../../Components/Commons/constants/constant";

export const paymentApi = createApi({
  reducerPath: "paymentApi",
  baseQuery: fetchBaseQuery({
    baseUrl: CONFIG.API_URL,
    prepareHeaders: (headers) => {
      headers.set("Access-Control-Allow-Origin", "*");
      headers.set("authorization", localStorage.getItem("jwt_token") || "");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    paymentTable: builder.mutation({
      query: (data) => ({
        url: "paymentDetails/outstandingRequests",
        method: "POST",
        body: data,
      }),
    }),
    completedPaymentTable: builder.mutation({
      query: (data) => ({
        url: "paymentDetails/completedRequests",
        method: "POST",
        body: data,
      }),
    }),
    unclaimedBalance: builder.mutation({
      query: (data) => ({
        url: "paymentDetails/sendRequest",
        method: "POST",
        body: data,
      }),
    }),
    balance: builder.query({
      query: () => ({
        url: "paymentDetails/balance",
        method: "GET",
      }),
    }),
    assest: builder.query({
      query: () => ({
        url: "paymentDetails/assets",
        method: "GET",
      }),
    }),
  }),
});

export const { usePaymentTableMutation, useBalanceQuery , useCompletedPaymentTableMutation,
  useUnclaimedBalanceMutation , useAssestQuery , useDownloadCompletedRequestsMutation , useDownloadOutstandingRequestsMutation
} = paymentApi; 
export default paymentApi;
