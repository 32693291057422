import React, { useState } from "react";
import "./Login.scss";
import InputCustom from "../../Ui/input/InputCustom";
import { ButtonCustom } from "../../Ui/button/ButtonCustom";
import { Modal, message } from "antd";
import { useForgotPasswordMutation } from "../../../Redux/apiService/loginApi";
import { encryption } from "../../Commons/commonFunctions/encryption";
import { useNavigate } from "react-router-dom";
import useApiErrorHandler from "../../Commons/customHooks/useApiErrorHandler";
import { emailValidationSchema } from "../../../Validations/emailSchema";

function ForgotPassword() {
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [forgotPassword] = useForgotPasswordMutation();
  const [forgotpassEmail, setForgotpassEmail] = useState("");
  const { handleApiError } = useApiErrorHandler();
  const [errors, setErrors] = useState("");

  const validateEmail = async (email) => {
    try {
      await emailValidationSchema.validate(email);
      setErrors("");
    } catch (error) {
      setErrors(error.message);
    }
  };

  const handleChange = (e) => {
    const email = e.target.value;
    setForgotpassEmail(email);
    validateEmail(email);
  };

  const handleContinueClick = async () => {
    try {
      await emailValidationSchema.validate(forgotpassEmail, {
        abortEarly: false,
      });
      const enc = await encryption(JSON.stringify({ email: forgotpassEmail }));
      const securedData = { dataString: enc };
      const data = await forgotPassword(securedData).unwrap();

      if (data?.status === true) {
        setIsModalVisible(true);
      } else {
        message.error(data?.message || "Failed to send email.");
      }
    } catch (error) {
      if (error.name === "ValidationError") {
        setErrors(error.message);
      } else {
        handleApiError(error);
      }
    }
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    navigate("/login");
  };

  return (
    <>
      <InputCustom
        basicInput
        inputCustum
        errorMsg={errors}
        required
        label="Email"
        placeholder="Enter Address"
        maxLength={100}
        value={forgotpassEmail}
        onChange={handleChange}
      />
      {/* {errors && <div className="error-message">{errors}</div>} */}

      <ButtonCustom
        greenBtn
        large
        label="Next"
        customClass="login_btn"
        onClick={handleContinueClick}
      />

      <Modal
        open={isModalVisible}
        onOk={handleModalClose}
        centered
        onCancel={handleModalClose}
        footer={null}
        closable={false}
        className="common-modal congratsModal"
      >
        <div className="modal-content">
          <h3>Congratulations!</h3>
          <p>
            A new password has been sent to your email. Please check and login
            again!
          </p>
          <ButtonCustom
            greenBtn
            large
            label="Continue"
            onClick={handleModalClose}
          />
        </div>
      </Modal>
    </>
  );
}

export default ForgotPassword;
