import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CONFIG } from "../../Components/Commons/constants/constant";

export const DashboardApi = createApi({
  reducerPath: "DashboardApi",
  baseQuery: fetchBaseQuery({
    baseUrl: CONFIG.API_URL,
    prepareHeaders: (headers) => {
      headers.set("Access-Control-Allow-Origin", "*");
      headers.set("authorization", localStorage.getItem("jwt_token") || "");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    dashboardData: builder.mutation({
      query: (data) => ({
        url: "dashboard/paymentDetail",
        method: "POST",
        body: data,
      }),
    }),
    dashboardPIData: builder.mutation({
      query: (data) => ({
        url: "dashboard/userPaymentSummaryByCoinChart",
        method: "POST",
        body: data,
      }),
    }),
    dashboardBarData: builder.mutation({
      query: (data) => ({
        url: "dashboard/userPaymentSummaryChart",
        method: "POST",
        body: data,
      }),
    }),
    dashboardKey: builder.query({
      query: () => ({
        url: "dashboard",
        method: "GET",
      }),
    }),
  }),
});

export const {
  useDashboardKeyQuery,
  useDashboardDataMutation,
  useDashboardPIDataMutation,
  useDashboardBarDataMutation,
} = DashboardApi;
export default DashboardApi;
