import React, { useState } from "react";
import "./Login.scss";
import InputCustom from "../../Ui/input/InputCustom.jsx";
import { ButtonCustom } from "../../Ui/button/ButtonCustom.jsx";
import { Link, useNavigate } from "react-router-dom";
import { Path } from "../../../Routing/Constant/RoutePaths.jsx";
import { useLoginMutation } from "../../../Redux/apiService/loginApi"; // Import the useLoginMutation hook
import { useDispatch } from "react-redux";
import { setTimeZone, setUser } from "../../../Redux/slices/userSlice"; // Import the setUser action
import { encryption } from "../../Commons/commonFunctions/encryption";
import { loginSchema } from "../../../Validations/loginValidationSchema"; // Import the validation schema
import { message } from "antd";
import useApiErrorHandler from "../../Commons/customHooks/useApiErrorHandler";
import NoData from "../../Commons/noData/NoData.jsx";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [login, { isLoading, error }] = useLoginMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleApiError } = useApiErrorHandler();

  const [errors, setErrors] = useState({});

  const handleChange = async (e) => {
    const { name, value } = e.target;

    // Update state
    if (name === "email") setEmail(value);
    if (name === "password") setPassword(value);

    // Validate the specific field
    try {
      await loginSchema.validateAt(name, { [name]: value });
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    } catch (err) {
      if (err.name === "ValidationError") {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: err.message }));
      }
    }
  };

  const handleLogin = async () => {
    if (isLoading) return; // Prevent multiple submissions

    try {
      const loginData = {
        email,
        password,
      };

      // Validate the form inputs
      await loginSchema.validate(loginData, { abortEarly: false });

      // If validation passes, proceed with API call
      let enc = await encryption(JSON.stringify(loginData));
      const securedData = { dataString: enc };
      const result = await login(securedData).unwrap();
      if (result?.status === true) {
        dispatch(setUser(result?.data));
        const detectedTimezone =
          Intl.DateTimeFormat().resolvedOptions().timeZone;
        dispatch(setTimeZone(detectedTimezone));
        localStorage.setItem("jwt_token", result?.token); // Assuming the token is in the response
        navigate(Path.DASHBOARD); // Redirect to the dashboard or another protected route
        message.success(result?.message);
      }
    } catch (err) {
      if (err.name === "ValidationError") {
        const formattedErrors = err.inner.reduce((acc, curr) => {
          acc[curr.path] = curr.message;
          return acc;
        }, {});
        setErrors(formattedErrors);
      } else {
        handleApiError(err); // Use the utility function for common API error handling
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !isLoading) {
      handleLogin();
    }
  };

  return (
    <div className="login" onKeyDown={handleKeyDown}>
      <InputCustom
        basicInput
        inputCustum
        required
        errorMsg={errors.email}
        name="email"
        label="Email"
        placeholder="Enter Address"
        maxLength={100}
        value={email}
        onChange={handleChange}
      />

      <InputCustom
        passwordInput
        inputCustum
        eyeicon
        required
        errorMsg={errors.password}
        name="password"
        label="Password"
        placeholder="Password"
        maxLength={100}
        value={password}
        onChange={handleChange}
      />

      <div className="login_link">
        <Link to={Path.FORGOTPASSWORD}>Forgot Password?</Link>
      </div>

      <ButtonCustom
        greenBtn
        xLarge
        label={isLoading ? "Logging in..." : "Login"}
        customClass="login_btn"
        onClick={handleLogin}
        disabled={isLoading}
      />
    </div>
  );
}

export default Login;
