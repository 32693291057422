import CurrencyPreferences from "../Components/Pages/CurrencyPreferences/CurrencyPreferences";
import Dashboard from "../Components/Pages/Dashboard/Dashboard";
import ForgotPassword from "../Components/Pages/Login/ForgotPassword";
import Login from "../Components/Pages/Login/Login";
import MerchantConfiguration from "../Components/Pages/MerchantConfig/MerchantConfiguration";
import PaymentDetails from "../Components/Pages/PaymentDetails/PaymentDetails";
import Settings from "../Components/Pages/Settings/Settings";
import TransactionDetails from "../Components/Pages/TransactionDetails/TransactionDetails";
import { Path } from "./Constant/RoutePaths";

export const PAGES = {
  PUBLIC_PAGES: [
    { path: Path.HOME, element: <Login /> },
    { path: Path.LOGIN, element: <Login /> },
    { path: Path.FORGOTPASSWORD, element: <ForgotPassword /> },
  ],

  PRIVATE_PAGES: [
    { path: Path.DASHBOARD, element: <Dashboard /> },
    { path: Path.TRANSACTIONS, element: <TransactionDetails /> },
    { path: Path.PREFERENCES, element: <CurrencyPreferences /> },
    { path: Path.SETTINGS, element: <Settings /> },
    { path: Path.PAYMENTDETAILS, element: <PaymentDetails /> },
    { path: Path.MERCHANTCONFIG, element: <MerchantConfiguration /> },
  ],
};
