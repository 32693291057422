import * as yup from "yup";

export const passwordSchema = yup.object().shape({
  oldPassword: yup.string().required("Old Password is required"),
  newPassword: yup
    .string()
    .required("New Password is required")
    .test(
      "password-strength",
      "Password must be between 8-15 characters, contain at least one uppercase letter, one lowercase letter, one number, and one special character",
      (value) => {
        if (!value) return true; // Skip validation if no value (required will handle it)
        return (
          value.length >= 8 && // Length validation
          value.length <= 15 &&
          /[A-Z]/.test(value) && // Uppercase letter
          /[a-z]/.test(value) && // Lowercase letter
          /\d/.test(value) && // Number
          /[!@#$%^&*(),.?":{}|<>[\]\\\/]/.test(value) // Special character
        );
      }
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Passwords must match")
    .required("Confirm Password is required"),
});
