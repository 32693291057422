import React from "react";
import "./Footer.scss";
function Footer() {
  return (
    <div className="footer">
      <p>©2024 TRISKEL - All rights reserved.</p>
    </div>
  );
}

export default Footer;
