import React from "react";
import PublicRoute from "./Public/Public.jsx";
import PrivateRoute from "./Private/Private.jsx";
import { PAGES } from "./Pages.jsx";
import { Navigate } from "react-router-dom";
import { Path } from "./Constant/RoutePaths.jsx";

const routes = [
  {
    path: "/",
    element: <PublicRoute />,
    children: PAGES?.PUBLIC_PAGES.map((page) => ({
      path: page.path,
      element: page.element,
    })),
  },
  {
    path: "/",
    element: <PrivateRoute />,
    children: PAGES?.PRIVATE_PAGES.map((page) => ({
      path: page.path,
      element: page.element,
    })),
  },
  {
    path: "*", // Handle invalid URLs
    element: <Navigate to={Path.DASHBOARD} replace={true} />, // Redirect to Dashboard if no match
  },
];
export default routes;
