import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CONFIG } from "../../Components/Commons/constants/constant";

export const merchantConfigApi = createApi({
  reducerPath: "merchantConfigApi",
  baseQuery: fetchBaseQuery({
    baseUrl: CONFIG.API_URL,
    prepareHeaders: (headers) => {
      headers.set("Access-Control-Allow-Origin", "*");
      headers.set("authorization", localStorage.getItem("jwt_token") || "");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    withdrawWalletList: builder.query({
      query: () => ({
        url: "merchantConfig/withdrawalWalletsList",
        method: "GET",
      }),
    }),
    enableDisableCoins: builder.mutation({
      query: (payload) => ({
        url: "merchantConfig/updateCoinStatus",
        method: "POST",
        body: payload,
      }),
    }),
    merchantConfigbalances: builder.mutation({
      query: (payload) => ({
        url: "/merchantConfig/balances",
        method: "POST",
        body: payload,
      }),
    }),
    assetsDropdownList: builder.query({
      query: () => ({
        url: "merchantConfig/allNetworks",
        method: "GET",
      }),
    }),
    merchantAddressWhitelist: builder.mutation({
      query: (payload) => ({
        url: "merchantConfig/updateWithdrawalWalletAddress",
        method: "POST",
        body: payload,
      }),
    }),
    sendFundRequest: builder.mutation({
      query: (payload) => ({
        url: "paymentDetails/sendRequest",
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const {
  useEnableDisableCoinsMutation,
  useMerchantAddressWhitelistMutation,
  useAssetsDropdownListQuery,
  useWithdrawWalletListQuery,
  useMerchantConfigbalancesMutation,
  useSendFundRequestMutation
} = merchantConfigApi;
export default merchantConfigApi;
