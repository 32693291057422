import React, { useEffect, useState } from "react";
import "./Settings.scss";
import { Col, Input, Modal, Row, message } from "antd";
import InputCustom from "../../Ui/input/InputCustom";
import { ButtonCustom } from "../../Ui/button/ButtonCustom";
import { useDispatch, useSelector } from "react-redux";
import { passwordSchema } from "../../../Validations/changePasswordValidationsSchema";
import { useNavigate } from "react-router-dom";
import {
  useChangePasswordMutation,
  useGetPublicKeyCryptoQuery,
} from "../../../Redux/apiService/settingsApi";
import { CopyIcon } from "../../../Assets/Assets";
import { handleCopy } from "../../Commons/commonFunctions/commonFunctions"
import { useUpdatePublicKeyCryptoMutation } from "../../../Redux/apiService/settingsApi";
import { logout } from "../../../Redux/slices/userSlice";
import { encryption } from "../../Commons/commonFunctions/encryption";
import { CONFIG } from "../../Commons/constants/constant";
import noImage from "./../../../Assets/noImage.png";
import useApiErrorHandler from "../../Commons/customHooks/useApiErrorHandler";
import { formatWalletAddress } from "../../Commons/commonFunctions/commonFunctions";

function Settings() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const [isModalVisible3, setIsModalVisible3] = useState(false);
  const merchantDetails = useSelector((state) => state?.user?.user);
  const publicKeyCrypto = useSelector((state) => state.user.public_key_crypto);
  console.log("Public Key Crypto:", publicKeyCrypto);

  const { handleApiError } = useApiErrorHandler();

  const dispatch = useDispatch();
  const [apiResponse, setApiResponse] = useState(publicKeyCrypto || "");
  const navigate = useNavigate();
  const [changePassword, { isLoading }] = useChangePasswordMutation();
  const [updatePublicKeyCrypto] = useUpdatePublicKeyCryptoMutation();

  const { data: getPublicKey, refetch } = useGetPublicKeyCryptoQuery(
    undefined,
    {
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
    }
  );

  const [publicKey, setPublicKey] = useState(""); // State for API Key
  const [publicKeyError, setPublicKeyError] = useState("");

  const handleOpenClick = (type) => {
    if (type === 1) {
      setIsModalVisible2(true);
    } else if (type === 2) {
      setIsModalVisible3(true);
    } else {
      setIsModalVisible(true);
    }
  };
  const handleModalClose = () => {
    setIsModalVisible(false);
  };
  const handleModalClose2 = () => {
    setIsModalVisible2(false);
  };
  const handleModalClose3 = () => {
    setIsModalVisible3(false);
  };
  const details = [
    { label: "Merchant Name", value: merchantDetails?.name },
    { label: "Registered Email", value: merchantDetails?.email },
    {
      label: "Phone Number",
      value: merchantDetails?.phone_number
        ? `+${merchantDetails.phone_number}`
        : "N/A",
    },
    { label: "Platform Name", value: (merchantDetails?.platform_name) },
    {
      label: "Platform URL",
      value: (
        <>
          {formatWalletAddress(merchantDetails?.platform_url)}
          <span
            style={{ marginLeft: "8px", cursor: "pointer" }}
            onClick={() => handleCopy(merchantDetails?.platform_url)}
          >
            <CopyIcon />
          </span>
        </>
      ),
    },
    merchantDetails?.date_of_approval
      ? {
        label: "Date of Approval", value: new Date(
          merchantDetails?.date_of_approval
        ).toLocaleDateString("en-US", {
          month: "short",
          day: "2-digit",
          year: "numeric",
        }) + ' | ' + new Date(
          merchantDetails?.date_of_approval
        ).toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        })
      }
      : null, // or you can use an alternative if you want to handle the null case
  ].filter((item) => item !== null);

  const [allState, setAllState] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    error: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      publicKey: "",
    },
  });

  const resetState = () => {
    setAllState({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      error: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
        publicKey: "",
      },
    });
  };

  useEffect(() => {
    setPublicKey(getPublicKey?.data?.public_key_crypto);
  }, [getPublicKey?.data?.public_key_crypto]);

  const handleSubmitApiKey = async () => {
    if (!publicKey) {
      setPublicKeyError("Public Key cannot be empty");
      //message.error("Public Key cannot be empty");
      return;
    }
    try {
      const result = await updatePublicKeyCrypto({
        publicKeyCrypto: publicKey,
      }).unwrap();
      message.success(result?.message);
      setApiResponse(result || "null");
    } catch (error) {
      console.log("ERROR", error);
      message.error("Failed to update public key.");
      setApiResponse("null");
    }
  };

  const handleValidation = async (values) => {
    try {
      // Validate the full form data
      await passwordSchema.validate(values, { abortEarly: false });
      return true;
    } catch (validationErrors) {
      const errors = validationErrors.inner.reduce((acc, err) => {
        return { ...acc, [err.path]: err.message };
      }, {});

      // Update the error state with validation errors
      setAllState((prevState) => ({
        ...prevState,
        error: {
          ...prevState.error,
          ...errors,
        },
      }));
      return false;
    }
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;

    // Clear the error for the specific field being updated
    setAllState((prevState) => ({
      ...prevState,
      [name]: value,
      error: {
        ...prevState.error,
        [name]: "",
      },
    }));

    // Validate the field being changed
    const validationData = {
      ...allState,
      [name]: value,
      confirmPassword:
        name === "newPassword" ? allState.confirmPassword : value,
      newPassword: name === "confirmPassword" ? allState.newPassword : value,
    };

    // Validate the field being changed
    await handleValidation(validationData);

    // If newPassword is updated, trigger confirmPassword validation
    if (name === "newPassword") {
      try {
        await passwordSchema.validateAt("confirmPassword", validationData);
        setAllState((prevState) => ({
          ...prevState,
          error: {
            ...prevState.error,
            confirmPassword: "",
          },
        }));
      } catch (validationError) {
        setAllState((prevState) => ({
          ...prevState,
          error: {
            ...prevState.error,
            confirmPassword: validationError.message,
          },
        }));
      }
    }
  };

  const handleChangePassword = async () => {
    const isValid = await handleValidation(allState);

    if (isValid) {
      try {
        const enc = await encryption(
          JSON.stringify({
            oldPassword: allState.oldPassword,
            newPassword: allState.newPassword,
          })
        );
        const securedData = { dataString: enc };
        const result = await changePassword(securedData).unwrap();
        message.success(result?.message);
        setTimeout(() => {
          dispatch(logout());
          navigate("/login");
        }, 0);
      } catch (error) {
        console.log("ERROR", error);
        handleApiError(error);
      } finally {
        resetState();
      }
    } else {
      console.log("Validation failed:", allState.error);
    }
  };

  return (
    <div className="setting">
      <div className="setting_top">
        <div className="setting_top_detail commonCardBG">
          {details?.map((detail, index) => (
            <div key={index}>
              <p>{detail.label}</p>
              <div className="ellipses">
                <h4>{detail.value}</h4>
              </div>
            </div>
          ))}
        </div>
        <div className="KYBDocument commonCardBG">
          <h2>KYB Documents</h2>
          <div className="KYBDocument_top">
            <div className="KYBDocumentCardOuter">
              <div className="KYBDocument_card" onClick={handleOpenClick}>
                <h5>Merchant Logo</h5>
                <h6>View</h6>
              </div>
            </div>
            <div className="KYBDocumentCardOuter">
              <div
                className="KYBDocument_card"
                onClick={() => handleOpenClick(1)}
              >
                <h5>ID Front</h5>
                <h6>View</h6>
              </div>
            </div>
            <div className="KYBDocumentCardOuter">
              <div
                className="KYBDocument_card"
                onClick={() => handleOpenClick(2)}
              >
                <h5>ID Back</h5>
                <h6>View</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="setting_head">
        <h2>Change Password</h2>
      </div>
      <Row gutter={[20, 20]} className="setting_inputs">
        <Col xs={24} lg={8}>
          <InputCustom
            basicInput
            inputCustum
            errorMsg={allState.error.oldPassword}
            required
            label="Old Password"
            placeholder="Enter Old Password"
            maxLength={100}
            name="oldPassword" // Name prop added
            value={allState.oldPassword}
            onChange={handleChange} // handleChange function called here
          />
        </Col>
        <Col xs={24} lg={8}>
          <InputCustom
            basicInput
            inputCustum
            required
            label="New Password"
            placeholder="Enter New Password"
            maxLength={100}
            name="newPassword" // Name prop added
            value={allState.newPassword}
            onChange={handleChange} // handleChange function called here
            errorMsg={allState.error.newPassword}
          />
        </Col>
        <Col xs={24} lg={8}>
          <InputCustom
            basicInput
            inputCustum
            required
            label="Confirm Password"
            placeholder="Confirm New Password"
            maxLength={100}
            name="confirmPassword" // Name prop added
            value={allState.confirmPassword}
            onChange={handleChange} // handleChange function called here
            errorMsg={allState.error.confirmPassword}
          />
        </Col>
      </Row>
      <ButtonCustom
        greenBtn
        large
        label="Save"
        customClass="setting_Btn"
        onClick={handleChangePassword}
        disabled={isLoading}
      />

      <div className="setting_head">
        <h2 style={{ marginBottom: "10px" }}> Add Public Key</h2>
      </div>
      <Row gutter={[20, 20]} className="setting_inputs">
        <Input.TextArea
          className={`inputCustum`}
          placeholder="Enter your Public Key"
          rows={4}
          value={publicKey || ""}
          onChange={(e) => {
            console.log("e.target.value >>>", e.target.value);
            if (e.target.value !== "") {
              setPublicKeyError("");
            }
            setPublicKey(e.target.value);
          }}
        />
        {/* <InputCustom
          textInput
          inputCustum
          required
          placeholder="Enter your Public Key"
          maxLength={100}
          name="publicKey" // Name prop added
          onChange={(e) => {
            console.log('e.target.value >>>', e.target.value);
            if (e.target.value !== '') {
              setPublicKeyError("");
            }
            setPublicKey(e.target.value)
          }}
          value={publicKey || ""}
        // errorMsg={publicKeyError}
        /> */}
        {publicKeyError && <p style={{ color: "red" }}>{publicKeyError}</p>}
      </Row>
      <ButtonCustom
        greenBtn
        large
        label="Submit"
        customClass="setting_Btn"
        onClick={handleSubmitApiKey} // Set the click handler
        disabled={isLoading} // Disable button while loading
      />

      <Modal
        open={isModalVisible}
        onOk={handleModalClose}
        centered
        onCancel={handleModalClose}
        footer={null}
        className="common-modal merchantLogo"
      >
        <div className="modal-content">
          <img src={`${CONFIG.S3_URL}/${merchantDetails?.logo}`} alt="" />
        </div>
      </Modal>

      <Modal
        open={isModalVisible2}
        onOk={handleModalClose2}
        centered
        onCancel={handleModalClose2}
        footer={null}
        className="common-modal merchantLogo"
      >
        <div className="modal-content">
          <img
            src={
              merchantDetails?.id_front_image != null
                ? `${CONFIG.S3_URL}/${merchantDetails?.id_front_image}`
                : noImage
            }
            alt=""
            height={500}
            width={100}
          />
        </div>
      </Modal>
      <Modal
        open={isModalVisible3}
        onOk={handleModalClose3}
        centered
        onCancel={handleModalClose3}
        footer={null}
        className="common-modal merchantLogo"
      >
        <div className="modal-content">
          <img
            src={
              merchantDetails?.id_back_image != null
                ? `${CONFIG.S3_URL}/${merchantDetails?.id_back_image}`
                : noImage
            }
            alt=""
            height={500}
            width={100}
          />
        </div>
      </Modal>
    </div>
  );
}

export default Settings;
