import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./App.scss";
import routes from "./Routing/Routes.jsx";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      children: routes,
    },
  ]);

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
