import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";

import userSlice from "./slices/userSlice"; // Assuming you have a userSlice
import { authApi } from "./apiService/loginApi";
import settingApi from "./apiService/settingsApi";
import searchSlice from "./slices/searchSlice";
import merchantConfigApi from "./apiService/merchantConfigApi";
import paymentApi from "./apiService/paymentApi";
import CurrencyPreferencesApi from "./apiService/currencyPreferences";
import DashboardApi from "./apiService/dashboardApi";
import {transactionApi} from "../Redux/apiService/userTransactionApi";


const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  user: userSlice,
  search: searchSlice,
  [authApi.reducerPath]: authApi.reducer,
  [settingApi.reducerPath]: settingApi.reducer,
  [merchantConfigApi.reducerPath]: merchantConfigApi.reducer,
  [paymentApi.reducerPath] : paymentApi.reducer,
  [CurrencyPreferencesApi.reducerPath] : CurrencyPreferencesApi.reducer,
  [DashboardApi.reducerPath] : DashboardApi.reducer,
  [transactionApi.reducerPath]: transactionApi.reducer,

});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi?.middleware,
      settingApi?.middleware,
      merchantConfigApi?.middleware,
      paymentApi?.middleware,
      CurrencyPreferencesApi?.middleware,
      DashboardApi?.middleware,
      transactionApi?.middleware,
      

    ),
});

const persistor = persistStore(store);

export { store, persistor };
