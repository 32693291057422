import { Modal, Tabs, message } from "antd";
import React, { useState, useEffect } from "react";
import "./PaymentDetails.scss";
import CompletedRequests from "./CompletedRequests";
import OutstandingRequests from "./OutstandingRequests";
import { ButtonCustom } from "../../Ui/button/ButtonCustom";
// import {

//   useUnclaimedBalanceMutation,
// } from "../../../Redux/apiService/paymentApi";
import useApiErrorHandler from "../../Commons/customHooks/useApiErrorHandler";
import { useSelector } from "react-redux";
import { calculateTimeAgo } from "../../Commons/commonFunctions/commonFunctions";
import { encryption } from "../../Commons/commonFunctions/encryption";

import "./PaymentDetails.scss";

function PaymentDetails() {
  // const { data: Balance, refetch } = useBalanceQuery(undefined, {
  //   refetchOnMountOrArgChange: true,
  //   refetchOnReconnect: true,
  // });
  // const [UnclaimedBalance, { isloading }] = useUnclaimedBalanceMutation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [totalBalance, setTotalBalance] = useState(0);
  const [unclaimedBalance, setUnclaimedBalance] = useState(0);
  const [pendingPayouts, setpendingPayouts] = useState("");
  const [requestedBalance, setRequestedBalance] = useState(0);
  const [timeAgo, setTimeAgo] = useState(""); // State to store the formatted time ago
  const [triggerOutstanding, setTriggerOutstanding] = useState(false); // Trigger state
  const [disableBtn, setDisableBtn] = useState(false);
  const { handleApiError } = useApiErrorHandler();
  const [activeKey, setActiveKey] = useState("1");

  const onChange = (key) => {
    setActiveKey(key);
  };
  // useEffect(() => {
  //   if (Balance && Balance.data) {
  //     console.log("BALANCE", Balance);
  //     setTotalBalance(Balance.data.totalBalance);
  //     setUnclaimedBalance(Balance.data.unclaimedBalance);
  //     setpendingPayouts(Balance.data.pendingPayouts);
  //     setRequestedBalance(Balance?.data?.requestedBalance);
  //     const updatedTime =
  //       Balance?.data?.lastUpdated != "" ? Balance?.data?.lastUpdated : null;
  //     if (updatedTime) {
  //       setTimeAgo(calculateTimeAgo(updatedTime));
  //     }
  //   }
  // }, [Balance]);
  // console.log("ohdvouewrvnwo", unclaimedBalance);

  // const handleRequest = async () => {
  //   try {
  //     setIsModalVisible(true);
  //   } catch (error) {
  //     handleApiError(error);
  //   }
  // };
  // const sendRequest = async () => {
  //   try {
  //     setDisableBtn(true);
  //     setIsModalVisible(false);
  //     const amount = unclaimedBalance?.toString();
  //     const payload = {
  //       amount,
  //     };
  //     const enc = await encryption(JSON.stringify(payload));
  //     const securedData = { dataString: enc };
  //     const response = await UnclaimedBalance(securedData).unwrap();
  //     if (response?.message) {
  //       message.success(response?.message);
  //     }
  //     refetch();
  //     setTriggerOutstanding((prev) => !prev);
  //     console.log("response of post the balances", response);
  //   } catch (error) {
  //     handleApiError(error);
  //   }
  // };

  // const handleModalClose = () => {
  //   setIsModalVisible(false);
  // };

  const items = [
    {
      key: "1",
      label: "Outstanding Request",
      children: <OutstandingRequests trigger={triggerOutstanding} />,
    },
    {
      key: "2",
      label: "Completed Requests",
      children: <CompletedRequests />,
    },
  ];
  return (
    <div className="payment">
      <div className="payment_cards">
        {/* Commented out the Total Earnings */}
        {/*
      <div className="payment_cards_items commonCardBG">
        <div className="payment_cards_top">
          <h3>${totalBalance}</h3>
          <p>Total Earnings {timeAgo ? (Last updated ${timeAgo}) : null}</p>
        </div>
      </div>
      */}

        {/* {pendingPayouts > 0 ? (
        <div className="payment_cards_items commonCardBG">
          <div className="payment_cards_top">
            <h3>${unclaimedBalance}</h3>
            <p>Unclaimed Balance</p>
          </div>
          <div>
            <ButtonCustom
              greenBtn
              large
              label="Requested"
              customClass="requestBtn"
              disabled
            />
          </div>
        </div>
      ) : (
        <div className="payment_cards_items commonCardBG">
          <div className="payment_cards_top">
            <h3>${unclaimedBalance}</h3>
            <p>Unclaimed Balance</p>
          </div>
          <div>
            <ButtonCustom
              greenBtn
              large
              label="Request Withdrawal"
              customClass="requestBtn"
              onClick={handleRequest}
              disabled={
                unclaimedBalance === 0 ||
                unclaimedBalance === null ||
                isloading ||
                disableBtn
              }
            />
          </div>
        </div>
      )} */}

        {/* Commented out the Requested Balance */}
        {/*
      <div className="payment_cards_items commonCardBG">
        <div className="payment_cards_top">
          <h3>${requestedBalance}</h3>
          <p>Requested Balance</p>
        </div>
      </div>
      */}
      </div>
      <Tabs activeKey={activeKey} onChange={onChange}>
        <Tabs.TabPane tab="Outstanding Request" key="1">
          {activeKey === "1" && <OutstandingRequests />}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Completed Requests" key="2">
          {activeKey === "2" && <CompletedRequests />}
        </Tabs.TabPane>
      </Tabs>{" "}
      {/* <Modal
        open={isModalVisible}
        onOk={handleModalClose}
        centered
        onCancel={handleModalClose}
        footer={null}
        maskClosable={false}
        className="common-modal alertModal"
      >
        <div className="modal-content">
          <h3>Send Request!</h3>
          <p>Are you sure you want to send the withdrawal request?</p>
           <div className="alertModal_footer">
          <ButtonCustom
            greenBtn
            large
            label="Yes"
            // onClick={() => sendRequest()}
            disabled={isloading || disableBtn}
          />
          <ButtonCustom
            borderBtn
            large
            label="No"
            onClick={handleModalClose}
          />
        </div> 
        </div>
      </Modal> */}
    </div>
  );
}

export default PaymentDetails;
